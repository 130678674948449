import React from 'react';

const RegulationsPage = () => {
  return ( 
    <>
    <h1>Regulamin sklepu www.muchynskiej.pl </h1>
    <section>
      <h2><span>§1 </span>Definicje</h2>
      <ol>
        <li><strong>Dni robocze</strong> - oznacza dni tygodnia od poniedziałku do piątku z wyłączeniem dni ustawowo wolnych od pracy.</li>
        <li><strong>Dostawa</strong> - oznacza czynność faktyczną polegającą na dostarczeniu Kupującemu przez Sprzedawcę, za pośrednictwem Dostawcy, Produktu określonego w Zamówieniu. Poszczególni Dostawcy, z którymi współpracuje Sprzedawca są wskazani na stronie internetowej Sklepu. </li>
        <li><strong>Kodeks Cywilny</strong> – Kodeks cywilny z dnia 23 kwietnia 1964 (t.j. Dz.U. z 2020 poz. 1740 ze zm.).</li>
        <li><strong>Konsument</strong> - Kupujący będący osobą fizyczną, który składa Zamówienie w celu niezwiązanym bezpośrednio z jego działalnością gospodarczą lub zawodową.</li>
        <li><strong>Kupujący</strong> – osoba fizyczna, w tym Konsument, osoba prawna lub jednostka organizacyjna nie będąca osobą prawną, której przepisy szczególne przyznają zdolność prawną składająca Zamówienie na Produkty.</li>
        <li><strong>Regulamin</strong> -  niniejszy Regulamin sklepu internetowego www.muchynskiej.pl stanowiący regulamin, o którym mowa w art. 8 ustawy z 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (t.j. Dz. U. 2020 poz. 344 ze zm.).</li>
        <li><strong>Produkty</strong> – oferowane przez Sprzedawcę wyroby własne lub wyroby innych producentów, w tym także wyroby wytwarzane przez Sprzedawcę na indywidualne zamówienie Kupującego. </li>
        <li><strong>Sklep/Sklep Internetowy</strong> – sklep internetowy prowadzony przez Sprzedawcę dostępny pod adresem internetowym: www.muchynskiej.pl.</li>
        <li><strong>Sprzedawca</strong> – Karolina Muchyńska prowadząca działalność gospodarczą pod firmą MUCHYńskiej Karolina Muchyńska, Lnisno 8, 96-126 Godzianów, Numer NIP: 8361876278, REGON: 388519744.</li>
        <li><strong>Strony</strong> – łącznie Sprzedawca i Kupujący.</li>
        <li><strong>Umowa</strong> - umowa sprzedaży Produktów w rozumieniu Kodeksu cywilnego zawierana pomiędzy Sprzedawcą a Kupującym.</li>
        <li><strong>Ustawa o prawach konsumenta</strong> - Ustawa o prawach konsumenta z 24 czerwca 2014r. (tj. Dz.U. z 2020 r. poz. 287 ze zm.).</li>
        <li><strong>Zamówienie</strong> – dobrowolne oświadczenie woli Kupującego określające jednoznacznie rodzaj i ilość Produktów, zmierzające bezpośrednio do zawarcia Umowy sprzedaży w tym przy wykorzystaniu środków porozumiewania się na odległość.</li>
      </ol>
    </section>
    <section>
      <h2><span>§2 </span>Postanowienia ogólne</h2>
      <ol>
        <li>Niniejsze Regulamin dotyczy wszystkich Umów sprzedaży zawieranych z wykorzystaniem funkcjonalności Sklepu Internetowego.</li>
        <li>Wszelkie prawa do Sklepu internetowego, w tym prawa autorskie, własności intelektualnej do jego nazwy, prawa do jego domeny internetowej, a także elementy graficzne, rozwiązania techniczne i inne elementy strony www Sklepu Internetowego, w szczególności kod HTML i XHTML, arkusze CSS, skrypty JavaScript i linki multimedialne należą do Sprzedawcy i podlegają ochronie prawnej pod kątem praw przysługujących Sprzedawcy.</li>
        <li>Kupujący ani żaden inny użytkownik nie ma prawa kopiować ani utrwalać elementów ani całości Sklepu Internetowego w jakikolwiek sposób i w jakiejkolwiek formie bez wyraźnej, uprzedniej zgody Sprzedawcy.</li>
        <li>Sprzedawca oświadcza, że wszystkie Produkty są nowe oraz wolne od wad fizycznych i prawnych oraz zostały legalnie wprowadzone na rynek polski.</li>
        <li>Na wyraźne Zamówienie Kupującego Produkty mogą być wytwarzane przez Sprzedawcę na indywidualne Zamówienie Kupującego lub dostosowane do jego indywidualnych wymagań.</li>
        <li>
          Dane teleadresowe przedsiębiorcy, umożliwiające Kupującemu kontakt ze Sprzedawcą:
          </li>
        <ol>
          <li>adres pocztowy: Lnisno 8, 96-126 Godzianów,</li>
          <li>numer telefonu: 663 973 335,</li>
          <li>adres poczty elektronicznej: muchynskiej@wp.pl. </li>
        </ol>
        <li>Sprzedawca dołoży starań, aby korzystanie ze Sklepu Internetowego było możliwe dla Kupujących z użyciem wszystkich popularnych przeglądarek internetowych, systemów operacyjnych, typów urządzeń oraz typów połączeń internetowych. Minimalne wymagania techniczne niezbędne do współpracy z Serwisem Internetowym: </li>
        <ol>
          <li>komputer, laptop lub inne urządzenie multimedialne z dostępem do Internetu;</li>
          <li>dostęp do poczty elektronicznej; </li>
          <li>przeglądarka internetowa: Mozilla Firefox w najnowszej wersji, Opera w wersji najnowszej, Google Chrome w wersji najnowszej, Safari w najnowszej wersji; Sklep Internetowy nie wspiera nie jest dostępny w przeglądarce Internet Explorer, </li>
          <li>zalecana minimalna rozdzielczość ekranu: 1024x768; </li>
          <li>włączenie w przeglądarce internetowej możliwości zapisu plików Cookies oraz obsługi Javascript. </li>
        </ol>
        <li>Kupujący mogą uzyskać dostęp do niniejszego Regulaminu w każdym czasie za pośrednictwem odsyłacza zamieszczonego na stronie głównej Sklepu Internetowego oraz pobrać go i sporządzić wydruk, lub utrwalić na dowolnym nośniku.</li>
        <li>Sprzedawca dokłada najwyższych starań, aby Kupujący mieli możliwość korzystania ze wszystkich funkcjonalności Sklepu przez 24 godziny na dobę, zastrzega jednak możliwość czasowego zawieszania funkcjonowania niektórych lub wszystkich funkcjonalności Sklepu  Internetowego w celu przeprowadzania jego konserwacji, aktualizacji lub napraw.</li>
        <li>Umieszczone w Sklepie Internetowym wizualizacje Produktów mogą nieznacznie różnić się od tych w rzeczywistości.</li>
        <li>Sprzedawca uprawniony jest do zmiany ofertowej Produktów, w tym ich wycofania, lub zmiany cen, bez uprzedzenia. Wycofanie Produktów z oferty lub zmiana cen nie dotyczy Zamówień przyjętych do realizacji.</li>
        <li>Kupujący korzystając ze stron Sklepu internetowego zobowiązany jest w szczególności do:</li>
        <ol>
          <li>niedostarczania i nieprzekazywania treści zabronionych przez przepisy prawa,</li>
          <li>niepodejmowania działań takich jak rozsyłanie lub umieszczanie w ramach Sklepu niezamówionej informacji handlowej,</li>
          <li>korzystania ze Sklepu Internetowego w sposób niezakłócający jego funkcjonowania,</li>
          <li>korzystania z wszelkich treści zamieszczonych w ramach Sklepu Internetowego jedynie w zakresie własnego użytku osobistego,</li>
          <li>korzystania ze Sklepu Internetowego w sposób zgodny z przepisami obowiązującego na terytorium Rzeczpospolitej Polskiej prawa i postanowieniami Regulaminu.</li>
        </ol>
      </ol>
    </section>
    <section>
      <h2><span>§3 </span>Zamówienia</h2>
      <ol>
        <li>Podane w Sklepie Internetowym informacje o Produktach, w szczególności zamieszczone opisy, parametry, zdjęcia oraz ceny nie stanowią oferty w rozumieniu art. 66 § 1 Kodeksu cywilnego, lecz stanowią zaproszenie do zawarcia umowy w rozumieniu art. 71 Kodeksu Cywilnego, </li>
        <li>Kupujący może złożyć Zamówienie za pomocą:</li>
        <ol>
          <li>funkcjonalności Sklepu Internetowego;</li>
          <li>poprzez wysłanie Zamówienia na adres: muchynskiej@wp.pl.  </li>
        </ol>
        <li>Zamówienie mogą być składane 24 godziny na dobę, 7 dni w tygodniu.</li>
        <li>Kupujący może złożyć Zamówienie z wykorzystaniem funkcjonalności Sklepu internetowego bez konieczności dokonywania rejestracji swoich danych lub zakładania konta klienta. W takim przypadku jest jednak zobowiązany do podania wszystkich danych niezbędnych do realizacji Zamówienia zgodnie z formularzem udostępnionym na stronach internetowych.</li>
        <li>W celu złożenia Zamówienia z wykorzystaniem funkcjonalności Sklepu internetowego należy wejść na stronę internetową Sklepu, a następnie wybrać oferowane tam Produkty, ich ilość, rozmiar (jeżeli dotyczy), dodając je do Koszyka. Po całościowym skomplementowaniu Zamówienia Kupujący przechodzi do „Koszyka” w celu ustalenia szczegółów realizacji Zamówienia takich jak sposób i termin Dostawy, forma płatności oraz podając dane umożliwiające kontakt i wysyłkę Produktów - podejmując kolejne czynności techniczne w oparciu o wyświetlane komunikaty i informacje. </li>
        <li>W trakcie procedury Zamawiania - do momentu naciśnięcia przycisku „Kupuję i płacę” - Kupujący ma możliwość modyfikacji wprowadzonych danych w tym także w zakresie wyboru Produktów i ich ilości. W tym celu należy kierować się wyświetlanymi komunikatami oraz informacjami dostępnymi w Sklepie Internetowym.</li>
        <li>Po podaniu przez Kupującego  wszystkich niezbędnych danych wyświetlone zostanie podsumowanie złożonego Zamówienia. Złożenie Zamówienia następuje w chwili wciśnięcia przycisku „Kupuję i płacę”.</li>
        <li>Kupujący składając Zamówienie za pośrednictwem poczty elektronicznej przesyła je na adres: muchynskiej@wp.pl. W przesłanej wiadomości należy podać w szczególności: nazwę Produktu, ilość, rozmiar (jeżeli dotycz), kolor lub inne cechy charakterystyczne oraz swoje dane teleadresowe niezbędne do Dostawy. Po otrzymaniu ww. wiadomości Sprzedawca prześle Kupującemu wiadomość zwrotną podając swoje dane, cenę wybranych Produktów, możliwy termin realizacji Dostawy oraz formy płatności, jak też informacje o wszystkich dodatkowych płatnościach jakie Kupujący mógłby ponieść w związku z zawarciem Umowy. Na podstawie przesłanej wiadomości Kupujący może złożyć Zamówienie przesyłając wiadomość na adres poczty elektronicznej Sprzedawcy wskazując przy tym formę płatności oraz wybrany przez siebie sposób Dostawy.</li>
        <li>W wypadku, gdy podane dane nie są kompletne, Sprzedawca skontaktuje się z Kupującym. Jeśli kontakt z Kupującym nie będzie możliwy, Sprzedawca ma prawo do anulowania Zamówienia.</li>
        <li>Każdorazowo w celu dokonania Zamówienia konieczne jest podanie przez Kupującego danych, w tym danych osobowych, umożliwiających kontakt i wysyłkę Produktów. Dane niezbędne do złożenia Zamówienia to: imię i nazwisko, adres e-mail, adres Dostawy oraz numer telefonu.</li>
        <li>Zamówienie zostanie zrealizowane pod warunkiem, że pozbawione wad Produkty są dostępne w Sklepie. W przypadku niedostępności części Produktów objętych Zamówieniem Kupujący jest informowany o tym fakcie i podejmuje decyzję o sposobie realizacji Zamówienia (częściowa realizacja lub anulowanie całości Zamówienia). </li>
        <li>Chwilą zawarcia Umowy sprzedaży Produktów jest wysłanie przez Sprzedawcę na adres e-mail podany przez Kupującego potwierdzenia przyjęcia Zamówienia do realizacji.  W mailu tym zostaną podane Kupującemu szczegółowe warunki realizacji Zamówienia, w szczególności numer Zamówienia, lista zakupionych Produktów, łączna suma do zapłaty zawierająca koszt przesyłki oraz dane niezbędne do dokonania płatności.</li>
        <li>W przypadku Produktów wytworzonych przez Sprzedawcę na indywidualne Zamówienie Kupującego lub dostosowane do jego indywidualnych wymagań termin realizacji, jak i cena takich Produktów ustalana jest indywidualnie pomiędzy Sprzedawcą i Kupującym z wykorzystaniem funkcjonalności Sklepu internetowego lub poczty email. </li>
        <li>Kupujący może także w celu ustalenia szczegółów Zamówienia skorzystać z numeru telefonu widocznego na stronie internetowej Sklepu. W takim przypadku Sprzedawca niezwłocznie potwierdzi Kupującemu w formie korespondencji email, o ile Kupujący przekazał w tym celu swój adres email, szczegóły Zamówienia ustalone za pośrednictwem telefonu. Sprzedawca zastrzega sobie prawo do udzielania upustów, rabatów i organizowania promocji związanych z Produktami. Zasady promocji lub konkursów będą regulowane odrębnymi regulaminami.</li>
        <li> Sprzedawca uprawniony jest do odstąpienia od realizacji Zamówienia w przypadku naruszenia przez Kupującego zasad niniejszego Regulaminu.</li>
        <li>Sprzedawca w każdym przypadku zastrzega sobie prawo weryfikacji dokonanego Zamówienia oraz jego anulowania w przypadkach uzasadniających wątpliwość, co do rzetelności takiego Zamówienia.</li>
      </ol>
    </section>
    <section>
      <h2><span>§4 </span>Koszty i termin Dostawy</h2>
      <ol>
        <li>Produkt wysyłany jest pod adres wskazany w Zamówieniu lub podany telefonicznie, a w przypadku wyboru Dostawy do wybranego paczkomatu, adresem Dostawy będzie adres wybranego przez Kupującego paczkomatu.</li>
        <li>Zamówienie jest realizowane przez Sprzedawcę (czas przygotowania Produktów do Dostawy):</li>
        <ol>
          <li>Produkty gotowe wybrane ze strony Sklepu internetowego - do 2 Dni roboczych;</li>
          <li>Produkty wykonane na indywidualne Zamówienie Kupującego lub dostosowane do jego indywidualnych wymagań – od 7 do 21 Dni roboczych, o ile w Zamówieniu nie ustalono innego terminu.</li>
        </ol>
        <li>Początek biegu terminu realizacji liczy się od daty wpłaty środków na konto Sprzedawcy (płatność przelewem) po uprzednim potwierdzeniu Zamówienia przez Sprzedawcę.</li>
        <li>Zamówienia złożone w dni wolne od pracy realizowane są począwszy od pierwszego Dnia roboczego następującego po dniach wolnych.</li>
        <li>Czas Dostawy wynosi od 1 do 3 Dni roboczych. Dostawa realizowana jest za pomocą wyspecjalizowanych firm kurierskich lub pocztowych na terenie Rzeczypospolitej Polskiej.</li>
        <li>W przypadku Zamówień składających się z kilku Produktów, które mają zostać dostarczone w jednej przesyłce, termin realizacji będzie uzależniony od terminu skompletowania przez Sprzedawcę ostatniego elementu danego Zamówienia, chyba, że Strony postanowią inaczej.</li>
        <li>Termin Dostawy przedłuża się odpowiednio w razie wystąpienia przeszkody uniemożliwiającej lub istotnie utrudniającej Dostawę, za którą Sprzedawca nie ponosi odpowiedzialności  (siła wyższa). O okolicznościach tych Sprzedawca niezwłocznie powiadomi w drodze korespondencji email Kupującego.</li>
        <li>Kupujący obciążany jest kosztami Dostawy określonymi w Zamówieniu. Wysokość opłat uzależniona jest od rodzaju transportu oraz miejsca Dostawy. Kupujący w każdej chwili może się zapoznać z cennikiem kosztów wysyłki na stronie Sprzedawcy pod linkiem www._______________.</li>
      </ol>
    </section>
    <section>
      <h2><span>§5 </span>Odbiór Produktu</h2>
      <ol>
        <li>Kupujący obowiązany jest zbadać doręczoną przesyłkę w czasie i w sposób przyjęty przy przesyłkach danego rodzaju. W razie stwierdzenia ubytku, niezgodności lub uszkodzenia przesyłki Kupujący ma prawo żądać od pracownika Dostawcy spisania właściwego protokołu. W przypadku, gdyby pracownik Dostawcy odmówi podpisania protokołu, nie należy przyjmować, ani kwitować przesyłki i niezwłocznie skontaktować się ze Sprzedawcą. Brak wykonania powyższej procedury nie pozbawia jednak praw ani roszczeń na zasadach ogólnych.</li>
        <li>W przypadku nieobecności Kupującego pod wskazanym przez niego w Zamówieniu adresem pracownik Dostawcy pozostawi awizo lub podejmie próbę kontaktu telefonicznego celem ustalenia terminu, w którym Kupujący będzie obecny. W przypadku zwrotnego odesłania zamówionego Produktu do Sklepu Internetowego przez Dostawcę, Sprzedawca skontaktuje się z Kupującym drogą elektroniczną lub telefonicznie, ustalając ponownie z termin i koszt Dostawy.</li>
      </ol>
    </section>
    <section>
      <h2><span>§6 </span>Płatności</h2>
      <ol>
        <li>Sprzedawca nie jest płatnikiem VAT i w związku z tym nie wystawia faktur VAT. Na żądanie Kupującego Sprzedawca wystawi fakturę dokumentującą sprzedaż.</li>
        <li>Sprzedawca wystawia potwierdzenie sprzedaży, które jest dołączane do przesłanych Produktów.</li>
        <li> Ceny Produktów podawane są w złotych polskich. Ceny podawane przy Produktach na stronie internetowej Sklepu nie zawierają informacji odnośnie kosztów Dostawy, oraz ewentualnych innych dodatkowych kosztów jakie Kupujący może być zobowiązany do poniesienia.</li>
        <li>Kupujący zobowiązany jest do zapłaty za Produkt Zamówiony, w tym za koszty Dostawy. Jeżeli Strony nie ustaliły inaczej płatność musi nastąpić nie później niż w terminie 3 dni od dnia potwierdzenia przez Kupującego Zamówienia.</li>
        <li>Kupujący może wybrać następujące formy płatności za zamówione Produkty:</li>
        <ol>
          <li>Przelew bankowy na rachunek bankowy Sprzedawcy,</li>
          <li>Karta płatnicza: Visa, Visa Electron, MasterCard, MasterCard Electronic, Maestro,</li>
          <li>Blik.</li>
        </ol>
        <li>Podmiotem świadczącym obsługę płatności online jest ____________.</li>
        <li>Kupujący nie jest uprawniony do dokonywania potrącenia własnych wierzytelności z wierzytelnościami wynikającymi z realizacji Zamówienia.</li>
        <li>W wypadku niewywiązania się przez Kupującego z płatności we wskazanym powyżej terminie, Sprzedawca wyznaczy mu dodatkowy termin na dokonanie płatności. W tym celu prześle na adres poczty e-mail wskazany w Zamówieniu stosowną informację o nowym terminie płatności wraz z informacją iż w razie bezskutecznego upływu tego terminu będzie uprawniona do odstąpienia od Umowy zgodnie z art. 491 § 1 Kodeksu Cywilnego.  W przypadku bezskutecznego upływu terminu Sprzedawca jest uprawniony do odstąpienia od Umowy poprzez przesłanie oświadczenia w formie korespondencji e-mail na wskazany przez Kupującego adres poczty e-mail.</li>
        <li>W przypadku konieczności zwrotu środków uprzednio wpłaconych przez Kupującego, Sprzedawca dokonuje zwrotu płatności przy użyciu takiego samego sposobu płatności, jakiego użył Kupujący, chyba że wyraźnie zgodził się on na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami.</li>
      </ol>
    </section>
    <section>
      <h2><span>§7 </span>Odstąpienie od umowy zawartej na odległość (Konsument)</h2>
      <ol>
        <li>Zgodnie z ustawą z dnia 30 maja 2014 r. o prawach konsumenta (t.j. Dz.U z 2020 r., poz. 287 z późniejszymi zmianami) Kupujący będący Konsumentem, który zawarł Umowę na odległość, ma prawo do odstąpienia od Umowy, bez podania przyczyny i bez ponoszenia kosztów, składając stosowne oświadczenie w terminie 14 dni kalendarzowych.</li>
        <li>Zgodnie z art. 38a ww. ustawy o prawach konsumenta prawo odstąpienia od Umowy, o którym mowa w niniejszym paragrafie przysługuje także Kupującemu będącemu osobą fizyczną zawierającą Umowę bezpośrednio związaną z jej działalnością gospodarczą, w sytuacji jednak gdy Umowa ta nie posiada dla niej charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej działalności gospodarczej.</li>
        <li>Bieg terminu do odstąpienia od Umowy rozpoczyna się w dniu dostarczenia Kupującemu Produktu, a w przypadku Produktów wysyłanych partiami w dniu dostarczenia Kupującemu ostatniego zamówionego Produktu.</li>
        <li>Kupujący może odstąpić od Umowy, składając Sprzedawcy oświadczenie o odstąpieniu od Umowy. Oświadczenie to może zostać przesłane w formie pisemnej lub drogą elektroniczną na adresy wskazany w § 2 ust. 6  Regulaminu. Oświadczenie można złożyć na formularzu, którego wzór stanowi załącznik nr 1 Regulaminu lub sformułować oświadczenie samodzielnie.</li>
        <li>Powinno ono zawierać listę zwracanych Produktów, datę ich odbioru, imię, nazwisko, adres Kupującego, datę oświadczenia oraz numer rachunku bankowego Kupującego w przypadku zgody na taką formę zwrotu płatności.</li>
        <li>Do zachowania terminu odstąpienia wystarczy wysłanie oświadczenia przed jego upływem.</li>
        <li>W przypadku odstąpienia od umowy zawartej na odległość Umowę uważa się za niezawartą.</li>
        <li>Sprzedawca ma obowiązek niezwłocznie przesłać Kupującemu e-mail z potwierdzeniem otrzymania oświadczenia o odstąpieniu od Umowy złożonego drogą elektroniczną.</li>
        <li>Jeżeli Konsument złożył oświadczenie o odstąpieniu od Umowy zanim Sprzedawca przyjął jego ofertę, oferta przestaje wiązać.</li>
        <li>Kupujący ma obowiązek zwrócić Produkty Sprzedawcy niezwłocznie, jednak nie później niż 14 dni od dnia, w którym odstąpił od Umowy, chyba, że Sprzedawca zaproponował, że sam odbierze Produkty. Do zachowania terminu wystarczy odesłanie Produktów przed jego upływem. Produkty należy zwrócić na adres wskazany w § 2 ust. 6 Regulaminu.</li>
        <li>Sprzedawca ma obowiązek niezwłocznie, nie później niż w terminie 14 dni od dnia otrzymania oświadczenia o odstąpieniu, zwrócić Kupującemu wszystkie dokonane przez niego płatności, w tym koszty Dostawy (z zastrzeżeniem pkt. 11 - 12 poniżej). W przypadku, gdy Produkt nie zostanie zwrócony przed upływem tego terminu, zwrot płatności nastąpi niezwłocznie po jego otrzymaniu i dokonaniu sprawdzenia zwracanego Produktu.</li>
        <li>Sprzedawca dokona zwrotu płatności przy użyciu takiego samego sposobu zapłaty, jakiego użył Kupujący, chyba, że Kupujący wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami. </li>
        <li>Jeżeli Kupujący wybrał sposób Dostawy Produktu inny niż najtańszy zwykły sposób Dostawy oferowany przez Sprzedawcę, Sprzedawca nie jest zobowiązany do zwrotu Kupującemu poniesionych przez niego dodatkowych kosztów.</li>
        <li>Kupujący ponosi jedynie bezpośrednie koszty zwrotu rzeczy.</li>
        <li>Kupujący ponosi odpowiedzialność za zmniejszenie wartości Produktów będące wynikiem korzystania z nich w sposób wykraczający poza konieczny do stwierdzenia charakteru, cech i funkcjonowania rzeczy, chyba, że Sprzedawca nie poinformował Kupującego o prawie odstąpienia od Umowy.</li>
        <li>Zgodnie z art. 38 Ustawy o prawach konsumenta prawo odstąpienia od umowy zawartej na odległość nie przysługuje Konsumentowi w odniesieniu do Umów:</li>
        <ol>
          <li>w której przedmiotem świadczenia jest Produkt dostarczany w zapieczętowanym opakowaniu, którego po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu,</li>
          <li>w której przedmiotem świadczenia są Produkty, które po dostarczeniu, ze względu na swój charakter, zostają nierozłącznie połączone z innymi rzeczami, </li>
          <li>w której przedmiotem świadczenia jest Produkt nieprefabrykowany, wyprodukowana według specyfikacji Kupującego lub służący zaspokojeniu jego zindywidualizowanych potrzeb.</li>
        </ol>
      </ol>
    </section>
    <section>
      <h2><span>§8 </span>Procedura reklamacji</h2>
      <ol>
        <li>Reklamacje odnośnie wad Produktów należy zgłaszać pisemnie na adres siedziby Sprzedawcy lub adres e-mail wskazany w § 2 ust. 6 Regulaminu określając rodzaj niezgodności oraz oczekiwania dotyczące sposobu realizacji reklamacji przez Sprzedawcę.</li>
        <li>Reklamacja zostanie rozpatrzona w ciągu 14 dni i przed upływem tego terminu Kupujący zostanie poinformowany o stanowisku Sprzedawcy.</li>
        <li>W przypadku uznania reklamacji w ramach, której Produkt ma zostać naprawiony lub wymieniony należy go odesłać na koszt Sprzedawcy na adres wskazany w § 2 ust. 6 Regulaminu.</li>
        <li>Naprawiony lub wymieniony Produkt odsyłany jest do Kupującego na koszt Sprzedawcy. </li>
      </ol>
    </section>
    <section>
      <h2><span>§9 </span>Odpowiedzialność</h2>
      <ol>
        <li> W każdym przypadku odpowiedzialność odszkodowawcza Sprzedawcy wobec Kupującego niebędącego Konsumentem jest ograniczona do rzeczywistej straty, nie więcej jednak niż do wysokości zapłaconej ceny.</li>
        <li>Sprzedawca dokłada należytej staranności w doborze tkanin wykorzystywanych do Produktów. Nie jest jednak producentem tych tkanin i nie gwarantuje, że nie wystąpią  nieoczekiwane barwienie takich tkanin, w szczególności w trakcie prania.</li>
        <li>Sprzedawca oświadcza, że w przypadku Produktów dla dzieci, Produkty te nie są zabawkami. W związku z tym:</li>
        <ol>
          <li>Należy stosować je jedynie przy obecności osoby dorosłej, zgodnie z ich przeznaczeniem,</li>
          <li>nie zaleca się ich używać podczas snu dziecka,</li>
          <li>nie służą do zabawy.</li>
        </ol>
      </ol>
    </section>
    <section>
      <h2><span>§10 </span>Polityka prywatności oraz ochrona danych osobowych</h2>
      <ol>
        <li>Administratorem danych osobowych zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) przetwarzanych w związku z obowiązkami wskazanymi w niniejszym OWRZ, Umową oraz realizacją Zamówienia jest  Karolina Muchyńska prowadząca działalność gospodarczą pod firmą MUCHYńskiej Karolina Muchyńska, Lnisno 8, 96-126 Godzianów, Numer NIP: 8361876278, REGON: 388519744. Szczegółowe dane dotyczące przetwarzania danych osobowych dostępne są na stronie internetowej pod adresem www._________________</li>
      </ol>
    </section>
    <section>
      <h2><span>§11 </span>Pozasądowe sposoby rozstrzygania reklamacji i dochodzenia roszczeń</h2>
      <ol>
        <li>Kupujący będący Konsumentem po wyczerpaniu trybu reklamacji, ma możliwość skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń m.in. korzystając z polubownego sądu konsumenckiego, rzecznika konsumentów lub innych organizacji publicznych. Skorzystanie z nich ma charakter dobrowolny i może mieć miejsce wyłącznie, gdy obie strony sporu wyrażą na to zgodę.</li>
        <li> W szczególności Konsument może się zwrócić do powiatowego (miejskiego) rzecznika konsumentów, lub organizacji społecznej, do której zadań należy ochrona konsumentów (m.in Federacji Konsumentów, Stowarzyszenia Konsumentów Polskich), może złożyć wniosek o rozstrzygnięcie sporu do stałego polubownego sądu konsumenckiego (więcej informacji na stronie: https://www.wiih.lodz.pl/?p=221) lub złożyć wniosek w sprawie pozasądowego rozwiązania sporu do Wojewódzkiego Inspektora Inspekcji Handlowej (Wojewódzki Inspektor Inspekcji Handlowej w Łodzi, ul. Gdańska 38, 90-730 Łódź, adres email: sekretariat@wiih.lodz.pl, adres strony internetowej: www.wiih.lodz.pl).</li>
        <li>Zasady dostępu do tych procedur dostępne są w siedzibach lub na stronach internetowych podmiotów uprawnionych do pozasądowego rozpatrywania sporów, czyli w szczególności są to rzecznicy praw konsumenta lub Wojewódzkie Inspektoraty Inspekcji Handlowej, których lista jest dostępna na stronie internetowej Urzędu Ochrony Konkurencji i Konsumentów pod adresem http://www.uokik.gov.pl/spory_konsumenckie.php. Sprzedawca informuje, że pod adresem http://ec.europa.eu/consumers/odr/  dostępna jest platforma internetowego systemu rozstrzygania sporów pomiędzy konsumentami i przedsiębiorcami na szczeblu unijnym (platforma ODR).</li>
      </ol>
    </section>
    <section>
      <h2><span>§12 </span>Postanowienia końcowe</h2>
      <ol>
        <li>Rozstrzyganie ewentualnych sporów powstałych między Sprzedawcą, a Kupującym, który jest Konsumentem w rozumieniu przepisów Kodeksu cywilnego, a które nie zostały rozstrzygnięte w pozasądowym rozpatrywaniu reklamacji, zostaje poddane sądom właściwym zgodnie z postanowieniami właściwych przepisów Kodeksu postępowania cywilnego.</li>
        <li> Rozstrzyganie ewentualnych sporów powstałych między Sprzedawcą a Kupującym, który nie jest Konsumentem, zostaje poddane sądowi właściwemu ze względu na siedzibę Sprzedawcy.</li>
        <li>Sprzedawca zastrzega sobie prawo wprowadzania zmian do niniejszego Regulaminu z zastrzeżeniem, iż do Umów zawartych przed zmianą Regulaminu stosuje się wersję obowiązującą w chwili złożenia Zamówienia przez Kupującego. Zmiana Regulaminu wchodzi w życie w terminie 7 dni od dnia opublikowania na Stronie Internetowej Sklepu.</li>
        <li>Prawem właściwym we wszelkich sprawach związanych z niniejszym Regulaminem, Umowami i ich realizacją jest prawo polskie.</li>
        <li>Niniejszy Regulamin wchodzi w życie z dniem ________________2021 roku.</li>
      </ol>
    </section>
    <section>
      <h2> Załącznik nr 1</h2>
      <p className="text-center"><strong>INFORMACJE DOTYCZĄCE KORZYSTANIA Z PRAWA ODSTĄPIENIA OD UMOWY</strong></p>
      <p>
          Mają Państwo prawo odstąpić od niniejszej umowy w terminie 14 dni bez podania jakiejkolwiek przyczyny. Termin do odstąpienia od Umowy wygasa po upływie 14 dni od dnia, w którym weszli Państwo w posiadanie Produktów lub w którym osoba trzecia inna niż przewoźnik i wskazana przez Państwa weszła w posiadanie rzeczy.
          Aby skorzystać z prawa odstąpienia od umowy, muszą Państwo poinformować nas o swojej decyzji o odstąpieniu od Umowy w drodze jednoznacznego oświadczenia (na przykład pismo wysłane pocztą, faksem lub pocztą elektroniczną). Kontakt możliwy jest z wykorzystaniem następujących danych: Karolina Muchyńska prowadząca działalność gospodarczą pod firmą MUCHYńskiej Karolina Muchyńska, Lnisno 8, 96-126 Godzianów, adres e-mail muchynskiej@wp.pl.
      </p>
      <p>
          Mogą Państwo skorzystać z wzoru formularza odstąpienia od Umowy, jednak nie jest to obowiązkowe. Aby zachować termin do odstąpienia od umowy, wystarczy, aby wysłali Państwo informację dotyczącą wykonania przysługującego Państwu prawa odstąpienia od umowy przed upływem terminu do odstąpienia od Umowy.
          Uwaga: Prawo odstąpienia od Umowy nie przysługuje, w przypadku spełnienia się przesłanek wyłączających to prawo, które zostały szczegółowo opisane w Regulaminie.
          W przypadku odstąpienia od niniejszej Umowy zwracamy Państwu wszystkie otrzymane od Państwa płatności, w tym koszty dostarczenia rzeczy (z wyjątkiem dodatkowych kosztów wynikających z wybranego przez Państwa sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia oferowany przez nas), niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym zostaliśmy poinformowani o Państwa decyzji o wykonaniu prawa odstąpienia od niniejszej Umowy. Zwrotu płatności dokonamy przy użyciu takich samych sposobów płatności, jakie zostały przez Państwa użyte w pierwotnej transakcji, chyba że wyraźnie zgodziliście się Państwo na inne rozwiązanie; w każdym przypadku nie poniosą Państwo żadnych opłat w związku z tym zwrotem. Możemy jednak wstrzymać się ze zwrotem płatności do czasu otrzymania rzeczy lub do czasu dostarczenia nam dowodu jej odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej
      </p>
      <p>
          <strong>FORMULARZ ODSTĄPIENIA OD UMOWY</strong><br />
          <small>(formularz ten należy wypełnić i odesłać tylko w przypadku chęci odstąpienia od umowy)</small><br />
          Adresat: 
          <br />Karolina Muchyńska prowadząca działalność gospodarczą pod firmą MUCHYńskiej Karolina Muchyńska, Lnisno 8, 96-126 Godzianów, adres e-mail muchynskiej@wp.pl
            
          <br />Niniejszym informuję o moim odstąpieniu od umowy sprzedaży następujących Produktów: 
          <br />__________________________________________________________
          <br />__________________________________________________________
          <br />__________________________________________________________
          <br />__________________________________________________________
          <br />
          Imię i nazwisko Konsumenta: <br />
          Data odbioru Produktu:<br />
          Adres Konsumenta:<br />
          Data złożenia niniejszego oświadczenia:<br /><br />
            
            
          Podpis Konsumenta<br />
          (niezbędny jedynie w wersji papierowej)
        </p>
        <a href="/assets/attachments/Formularz odstąpienia od umowy.docx" download>Formularz odstąpienia od umowy</a>
    </section>
    <section>
      <h2>INFORMACJE DOTYCZĄCE KORZYSTANIA Z PRAWA ODSTĄPIENIA OD UMOWY</h2>
      <p>Mają Państwo prawo odstąpić od niniejszej umowy w terminie 14 dni bez podania jakiejkolwiek przyczyny. Termin do odstąpienia od Umowy wygasa po upływie 14 dni od dnia, w którym weszli Państwo w posiadanie Produktów lub w którym osoba trzecia inna niż przewoźnik i wskazana przez Państwa weszła w posiadanie rzeczy.
      Aby skorzystać z prawa odstąpienia od umowy, muszą Państwo poinformować nas o swojej decyzji o odstąpieniu od Umowy w drodze jednoznacznego oświadczenia (na przykład pismo wysłane pocztą, faksem lub pocztą elektroniczną). Kontakt możliwy jest z wykorzystaniem następujących danych: Karolina Muchyńska prowadząca działalność gospodarczą pod firmą MUCHYńskiej Karolina Muchyńska, Lnisno 8, 96-126 Godzianów, adres e-mail muchynskiej@wp.pl.</p>
      <p>Mogą Państwo skorzystać z wzoru formularza odstąpienia od Umowy, jednak nie jest to obowiązkowe. Aby zachować termin do odstąpienia od umowy, wystarczy, aby wysłali Państwo informację dotyczącą wykonania przysługującego Państwu prawa odstąpienia od umowy przed upływem terminu do odstąpienia od Umowy.
      </p>
      <p>Uwaga: Prawo odstąpienia od Umowy nie przysługuje, w przypadku spełnienia się przesłanek wyłączających to prawo, które zostały szczegółowo opisane w Regulaminie.</p>
      <p>W przypadku odstąpienia od niniejszej Umowy zwracamy Państwu wszystkie otrzymane od Państwa płatności, w tym koszty dostarczenia rzeczy (z wyjątkiem dodatkowych kosztów wynikających z wybranego przez Państwa sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia oferowany przez nas), niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym zostaliśmy poinformowani o Państwa decyzji o wykonaniu prawa odstąpienia od niniejszej Umowy. Zwrotu płatności dokonamy przy użyciu takich samych sposobów płatności, jakie zostały przez Państwa użyte w pierwotnej transakcji, chyba że wyraźnie zgodziliście się Państwo na inne rozwiązanie; w każdym przypadku nie poniosą Państwo żadnych opłat w związku z tym zwrotem. Możemy jednak wstrzymać się ze zwrotem płatności do czasu otrzymania rzeczy lub do czasu dostarczenia nam dowodu jej odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej.</p>
    </section>
    </>
  );
}
 
export default RegulationsPage;
import React from "react";

import "./Header.css";
import logo from "../../images/logo.png";

const Header = () => {
  return (
    <header>
      <h1>
        Muchy <br /> poszetki <br />
        opaski
      </h1>
      <img src={logo} alt="logo-muchynskiej" />
    </header>
  );
};

export default Header;

import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import "./Navbar.css";

import CartButton from "../../features/Cart/CartButton/CartButton";
import OpenMenuButton from "./OpenMenuButton";
import CloseMenuButton from "./CloseMenuButton";
import { ProductContext } from "../../state/ProductContext";
import { mapCategoriesToLinks } from "../../helpers/RouteMapper";

const Navbar = () => {
  const [isMenuActive, setMenuActive] = useState(false);
  const { categories } = useContext(ProductContext);

  const handleIsMenuActive = () => setMenuActive((prev) => !prev);
  const toggleCategoryActive = (event) => {
    event.stopPropagation();
    const mql = window.matchMedia("(max-width: 1024px)");
    if (mql.matches) event.target.parentNode.classList.toggle("expanded");
  };

  const categoriesLinks = mapCategoriesToLinks(
    categories,
    toggleCategoryActive,
    handleIsMenuActive
  );

  return (
    <>
      <nav>
        <OpenMenuButton action={handleIsMenuActive} />
        <ul className={isMenuActive ? "active" : ""}>
          <CloseMenuButton action={handleIsMenuActive} />
          <li>
            <NavLink to="/" exact onClick={handleIsMenuActive}>
              MUCHYńskiej
            </NavLink>
          </li>
          {categoriesLinks}
          <li>
            <NavLink to="/nowosci" onClick={handleIsMenuActive}>
              NOWOŚCI
            </NavLink>
          </li>
          <li>
            <NavLink to="/promocje" onClick={handleIsMenuActive}>
              PROMOCJE
            </NavLink>
          </li>
        </ul>
        <CartButton />
        {/* <Signin /> */}
      </nav>
    </>
  );
};

export default Navbar;

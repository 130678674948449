import React from "react";

import ProductsList from "../../features/Products/ProductsList";
import { getLatestProducts } from "../../helpers/Queries";

const LatestPage = () => {
  return (
    <ProductsList
      name={"Nowości"}
      productsQuery={getLatestProducts}
      hidePager
    />
  );
};

export default LatestPage;

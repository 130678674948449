import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { StageContext } from "../../state/StageContext";
import { ProductContext } from "../../state/ProductContext";
import Bill from "./Bill";
import Shipment from "./Shipment";
import StagesBar from "./StagesBar/StagesBar";
import Summary from "./Summary";

const LayoutController = () => {
  const { cartItems } = useContext(ProductContext);
  const { SHIPMENT_STAGE, SUMMARY_STAGE, stage } = useContext(StageContext);

  // eslint-disable-next-line no-unused-vars

  const history = useHistory();
  useEffect(() => {
    if (cartItems.length === 0) history.push("/koszyk");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StagesBar />
      {stage.order === SHIPMENT_STAGE.order ? <Shipment /> : null}
      {stage.order === SUMMARY_STAGE.order ? <Summary /> : null}
      <Bill />
    </>
  );
};

export default LayoutController;

import React, { useState, useEffect } from "react";

const Pager = ({ page, setPage, limit, totalCount }) => {
  const [pagesNumberList, setPagesNumberList] = useState(null);

  useEffect(() => {
    const createPagesNumberListUI = () => {
      const listElements = [];
      const pageCount = Math.ceil(totalCount / limit);

      for (let i = 1; i <= pageCount; i++) {
        let className = '';
        if(i === page - 1) className = 'previous-page';
        else if(className === page) className = 'active';

        listElements.push(
          <li key={i} onClick={() => setPage(i)} className={className}>
            {i}
          </li>
        )
      }

      const list = <ul>{listElements}</ul>;
      setPagesNumberList(list);
    };
    createPagesNumberListUI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, totalCount]);

  return (
    <div className="pager">
      {pagesNumberList}
    </div>
  );
};

export default Pager;

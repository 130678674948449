import React, { useContext } from "react";
import { ProductContext } from "../../../state/ProductContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

import "./CartSummary.css";
import { pricify } from "../../../helpers/HelperFunctions";

const CartSummary = () => {
  const { cartItems } = useContext(ProductContext);
  let sum = 0.0;

  cartItems.forEach((item) => {
    const price =
      item.product.promotionPrice > 0
        ? item.product.chosenVariant.promotionPrice
        : item.product.chosenVariant.price;
    const quantity = item.quantity;
    sum += price * quantity;
  });

  return (
    <section className="cart-summary">
      <h3>
        Łączna kwota <span className="total-price">{pricify(sum)}zł</span>
      </h3>
      <NavLink to="/zamowienie" className="go-to-order">
        <span>Przejdź do dostawy</span> <FontAwesomeIcon icon={faAngleRight} size="2x" />
      </NavLink>
    </section>
  );
};

export default CartSummary;

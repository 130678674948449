import React, { useContext } from "react";
import { ProductContext } from "../../state/ProductContext";
import { mapCategoriesToLinks } from "../../helpers/RouteMapper";
import "./SideMenu.css";

const SideMenu = () => {
  const { categories } = useContext(ProductContext);

  const categoriesLinks = mapCategoriesToLinks(categories);

  return (
    <aside className="side-menu">
      <h2>Oferta</h2>
      <ul>{categoriesLinks}</ul>
    </aside>
  );
};

export default SideMenu;

import React from "react";
import { useHistory } from "react-router";
import { config } from "../../config/config";
import { pricify } from "../../helpers/HelperFunctions";
import { convertToLink } from "../../helpers/RouteMapper";
import AddToCartButton from "./AddToCartButton";

const ProductCard = ({ product }) => {
  const defaultVariant = {size: 'M', price: product.price, promotionPrice: product.promotionPrice};
  const history = useHistory();
  const isInPromotion = product.promotionPrice > 0;
  const handleProductClick = () => {
    const categoryLink = convertToLink(product.category.parentCategory.name);
    const subcategoryLink = convertToLink(product.category.name);
    const location = {
      pathname: `${categoryLink}${subcategoryLink}/${product.id}`,
    };

    history.push(location);
  };

  const priceBlock = (
    <section
      className={isInPromotion ? "price-block in-promotion" : "price-block"}
    >
      {isInPromotion && (
        <p className="promotion-price">{pricify(product.promotionPrice)}zł</p>
      )}
      <p className="price">{pricify(product.price)}zł</p>
    </section>
  );

  return (
    <div className="product-card" key={product.id} onClick={handleProductClick}>
      <img
        src={`${config.API_URL}${product.images[0]?.url}`}
        alt={product.images[0]?.url}
      />
      <h4 className="name">{product.name}</h4>
      {priceBlock}
      <AddToCartButton product={{...product, chosenVariant: defaultVariant}} quantity={1} />
    </div>
  );
};

export default ProductCard;

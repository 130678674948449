import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";

const Footer = () => (
  <>
    <footer>
      <div className="wrapper">
        <section className="account">
          <h3>Moje konto</h3>
          <NavLink to="/moje-zamowienia">Moje zamówienia</NavLink>
          <NavLink to="/ustawienia-konta">Ustawienia konta</NavLink>
          <NavLink to="/przechowalnia">Przechowalnia</NavLink>
        </section>
        <section className="payment">
          <h3>Płatność i dostawa</h3>
          <NavLink to="/formy-platnosci">Formy płatności</NavLink>
          <NavLink to="/czas-realizacji">Czas realizacji</NavLink>
          <NavLink to="/zwroty">Zwroty</NavLink>
        </section>
        <section className="info">
          <h3>Informacje</h3>
          <NavLink to="/regulamin">Regulamin</NavLink>
          <NavLink to="/polityka-prywatnosci">Polityka prywatności</NavLink>
          <NavLink to="opcja-pakowania">Opcja pakowania</NavLink>
        </section>
        <section className="about">
          <h3>O mnie</h3>
          <NavLink to="/kontakt">Kontakt</NavLink>
          <NavLink to="/dane-firmy">Dane firmy</NavLink>
          <div className="socials">
            <a
              className="social facebook"
              href="https://www.facebook.com/MUCHY%C5%84skiej-124475038943888"
            >
              <FontAwesomeIcon icon={faFacebookF} size="2x" />
            </a>
            <a
              className="social instagram"
              href="https://www.instagram.com/muchy_nskiej/"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
          </div>
        </section>
      </div>
      <div className="author">
        <p>&copy; MUCHYńskiej, 2021</p>
        <address>
          Realizacja:{" "}
          <a href="mailto:danielleczycki@gmail.com">Daniel Łęczycki</a>
        </address>
      </div>
    </footer>
  </>
);

export default Footer;

import React, {useContext} from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { config } from "../../config/config";

import {ToastContext} from '../../state/ToastContext';
import {INFO} from '../../constants/words';

const CopyLinkButton = () => {
  const location = useLocation();
  const {showToast} = useContext(ToastContext);

  const handleOnClick = () => {
    console.log(location.pathname);
    navigator.clipboard.writeText(
      `${config.API_URL}${location.pathname}`
    );
    showToast(INFO, 'Link', 'Skopiowano adres strony');
  };

  return (
    <button className="share" onClick={handleOnClick}>
      <FontAwesomeIcon icon={faCopy} size="3x" />
    </button>
  );
};

export default CopyLinkButton;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const ExpandMenuButton = () => {
  const handleExpandClick = (event) => {
    event.stopPropagation();
    event.target.parentNode.classList.toggle("expanded");
  };

  return (
    <button onClick={handleExpandClick} className="expand-menu">
      <FontAwesomeIcon icon={faAngleDown} size="4x" />
    </button>
  );
};

export default ExpandMenuButton;

import React, { useContext } from "react";
import { pricify } from "../../helpers/HelperFunctions";
import { OrderContext } from "../../state/OrderContext";
import { StageContext } from "../../state/StageContext";

const Summary = () => {
  const {
    recipient,
    deliveries,
    selectedDelivery,
    payments,
    selectedPayment,
    selectedOptions,
  } = useContext(OrderContext);
  const { SUMMARY_STAGE } = useContext(StageContext);

  const recipientElement = createRecipientElement();
  const deliveryElement = createDeliveryElement();
  const paymentElement = createPaymentElement();
  const optionsElement = createOptionsElement();

  function createRecipientElement() {
    return (
      <div>
        <p>{recipient.name}</p>
        <p>{recipient.address}</p>
        <p>
          {recipient.postalCode} {recipient.city}
        </p>
        <p>{recipient.email}</p>
        {recipient.phone ? <p>{recipient.phone}</p> : null}
      </div>
    );
  }
  function createDeliveryElement() {
    const delivery = deliveries.find(
      (delivery) => delivery.means === selectedDelivery.means
    );

    return (
      <div>
        <span>
          {delivery.fullName}{" "}
          <span className="shipment-price">
            ({pricify(delivery.price)}zł)
          </span>
        </span>
        <img src={delivery.image} alt={delivery.fullName} />
      </div>
    );
  }
  function createPaymentElement() {
    const paymentText = payments.find(
      (payment) => payment.method === selectedPayment.method
    ).fullName;
    const paymentImage = payments.find(
      (payment) => payment.method === selectedPayment.method
    ).image;
    return (
      <div>
        <span>{paymentText}</span>
        <img src={paymentImage} alt={selectedPayment.method} />
      </div>
    );
  }
  function createOptionsElement() {
    if (!selectedOptions.giftWrapping) return false;
    return (
      <div>
        <p>
          Pakowanie na prezent <span>(10,00 zł)</span>
        </p>
      </div>
    );
  }

  return (
    <section className="summary">
      <h1>Podsumowanie</h1>
      <section className="recipient">
        <h2>Odbiorca</h2>
        {recipientElement}
      </section>
      <section className="delivery">
        <h2>Dostawa</h2>
        {deliveryElement}
      </section>
      <section className="payment">
        <h2>Płatność</h2>
        {paymentElement}
      </section>
      {optionsElement ? (
        <section className="options">
          <h2>Dodatkowe opcje</h2>
          {optionsElement}
        </section>
      ) : null}
      <button
        className="back-to-shipment"
        onClick={SUMMARY_STAGE.previousStageCallback}
      >
        Powrót
      </button>
    </section>
  );
};

export default Summary;

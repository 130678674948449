import { createContext, useEffect, useState } from "react";
import inpostImage from "../images/inpost.png";
import przelewy24Image from "../images/przelewy24.jpg";

import { validateRecipient, validateAgreements } from "../helpers/Validators";

export const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const deliveries = [
    {
      means: "inpost",
      fullName: "InPost",
      image: inpostImage,
      price: 1500,
    },
  ];
  const payments = [
    {
      method: "przelewy24",
      fullName: "Przelewy24",
      image: przelewy24Image,
    },
  ];
  const options = [
    {
      abbreviation: "gift-wrapping",
      fullName: "Pakowanie prezentowe",
      price: 1000,
    },
  ];

  const [recipient, setRecipient] = useState({
    name: "",
    address: "",
    postalCode: "",
    city: "",
    email: "",
    phone: "",
  });
  const [recipientErrors, setRecipientErrors] = useState({
    name: ["Pole jest wymagane"],
    address: ["Pole jest wymagane"],
    postalCode: [
      "Pole jest wymagane",
      "Podany kod pocztowy jest nieprawidłowy",
    ],
    city: ["Pole jest wymagane"],
    email: ["Pole jest wymagane", "Podany adres email jest nieprawidłowy"],
    phone: ["Pole jest wymagane", "Podany numer jest nieprawidłowy"],
  });
  const [selectedDelivery, setSelectedDelivery] = useState({
    means: "inpost",
  });
  const [selectedPayment, setSelectedPayment] = useState({
    method: "przelewy24",
  });
  const [selectedOptions, setSelectedOptions] = useState({ giftWrapping: false });
  const [selectedAgreements, setSelectedAgreements] = useState({
    acceptTerms: false,
  });
  const [selectedAgreementsErrors, setSelectedAgreementsErrors] = useState({
    acceptTerms: ["Pole musi być zaznaczone"],
  });
  const [showErrors, setShowErrors] = useState(false);
  const [amount, setAmount] = useState(0.0);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const resetSelectedOptions = () => {
    setRecipient({
      name: "",
      address: "",
      postalCode: "",
      city: "",
      email: "",
      phone: "",
    });
    setSelectedOptions({ giftWrapping: false });
    setSelectedAgreements({ acceptTerms: false, errors: { acceptTerms: [] } });
    setShowErrors(false);
  };

  const handleRecipientChange = (e) => {
    const newRecipientData = { ...recipient, [e.target.name]: e.target.value };
    setRecipient(newRecipientData);
  };

  const handleSelectedDeliveryChange = (e) =>
    setSelectedDelivery({ means: e.target.value });

  const handleSelectedPaymentChange = (e) =>
    setSelectedPayment({ method: e.target.value });

  const handleSelectedOptionsChange = () =>
    setSelectedOptions((prevState) => ({ giftWrapping: !prevState.giftWrapping }));

  const handleSelectedAgreementsChange = () => {
    setSelectedAgreements((prevState) => ({
      acceptTerms: !prevState.acceptTerms,
    }));
  };

  const handleOrderSubmit = async () => {
    setIsPaymentModalOpen(true);
  };

  const closePaymentModal = () => setIsPaymentModalOpen(false);

  function isOrderDataValid(recipientErrors, selectedAgreementsErrors) {
    let orderValid = true;

    if (
      recipientErrors.name.length !== 0 ||
      recipientErrors.address.length !== 0 ||
      recipientErrors.postalCode.length !== 0 ||
      recipientErrors.city.length !== 0 ||
      recipientErrors.email.length !== 0 ||
      recipientErrors.phone.length !== 0 ||
      selectedAgreementsErrors.acceptTerms.length !== 0
    ) {
      orderValid = false;
      setShowErrors(true);
    }

    return orderValid;
  }

  useEffect(() => {
    const errors = validateRecipient(recipient);
    setRecipientErrors(errors);
  }, [
    recipient.name,
    recipient.address,
    recipient.postalCode,
    recipient.city,
    recipient.email,
    recipient.phone,
    recipient,
  ]);

  useEffect(() => {
    const errors = validateAgreements(selectedAgreements);
    setSelectedAgreementsErrors(errors);
  }, [selectedAgreements.acceptTerms, selectedAgreements]);

  return (
    <OrderContext.Provider
      value={{
        recipient,
        recipientErrors,
        handleRecipientChange,
        deliveries,
        selectedDelivery,
        handleSelectedDeliveryChange,
        payments,
        selectedPayment,
        handleSelectedPaymentChange,
        options,
        selectedOptions,
        handleSelectedOptionsChange,
        selectedAgreements,
        selectedAgreementsErrors,
        handleSelectedAgreementsChange,
        resetSelectedOptions,
        amount,
        setAmount,
        isPaymentModalOpen,
        closePaymentModal,
        handleOrderSubmit,
        isOrderDataValid,
        showErrors,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;

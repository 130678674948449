import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const CloseMenuButton = ({ action }) => {
  return (
    <button onClick={action} className="close-menu">
      <FontAwesomeIcon icon={faTimes} size="4x" />
    </button>
  );
};

export default CloseMenuButton;

import React from "react";

import ProductsList from "../../features/Products/ProductsList";
import {
  getProductsInPromotion,
  getProductsInPromotionCount,
} from "../../helpers/Queries";

const PromotionsPage = () => {
  return (
    <ProductsList
      name={"Promocje"}
      productsQuery={getProductsInPromotion}
      countQuery={getProductsInPromotionCount}
    />
  );
};

export default PromotionsPage;

import React, { useContext, useEffect, useState } from "react";

import "./PaymentController.css";

import { OrderContext } from "../../../state/OrderContext";
import P24Modal from "../P24Modal/P24Modal";
import { ProductContext } from "../../../state/ProductContext";

const PaymentController = () => {
  const { cartItems } = useContext(ProductContext);
  const {
    isPaymentModalOpen,
    recipient,
    amount,
    selectedPayment,
    selectedOptions,
    selectedDelivery,
  } = useContext(OrderContext);
  const [order, setOrder] = useState({});

  useEffect(() => {
    setOrder({
      ...recipient,
      amount,
      orderProducts: cartItems,
      payment: selectedPayment,
      options: selectedOptions,
      delivery: selectedDelivery,
    });
  }, [
    recipient,
    amount,
    cartItems,
    selectedPayment,
    selectedOptions,
    selectedDelivery,
  ]);

  return (
    <>
        {isPaymentModalOpen &&
          order.payment.method === "przelewy24" && (
            <P24Modal order={order} />
          )}
    </>
  );
};

export default PaymentController;

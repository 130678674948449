import { config } from "../config/config";
import { PRODUCTS_LIMIT, RECOMMENDED_PRODUCTS_COUNT } from "../constants/numbers";

// TODO (FUTURE): zmienić żądania i API na zgodne z REST (specyfikacja żądań typu sortowanie w QS a nie osobnych ścieżkach)
export async function getCategories() {
  const response = await fetch(`${config.API_URL}/categories?available=true`);
  const categories = await response.json();
  return categories;
}

export async function getCategoriesProducts(categoryId, page, limit){
  const offset = limit * page - limit;
  const response = await fetch(`${config.API_URL}/products?categoryId=${categoryId}&offset=${offset}&limit=${limit}`);
  const products = await response.json();
  return products;
}

export async function getCategoriesProductsCount(categoryId) {
  const response = await fetch(`${config.API_URL}/products/count?categoryId=${categoryId}`);
  const {count} = await response.json();
  return count;
}

export async function getRecommendedProducts() {
  const response = await fetch(`${config.API_URL}/products/latest?limit=${RECOMMENDED_PRODUCTS_COUNT}`);
  const products = await response.json();
  return products;
}

export async function getLatestProducts() {
  const response = await fetch(`${config.API_URL}/products/latest?limit=${PRODUCTS_LIMIT}`);
  const products = await response.json();
  return products;
}

export async function getProductsInPromotion(page, limit){
  const offset = limit * page - limit;
  const response = await fetch(`${config.API_URL}/products?inPromotion=true&offset=${offset}&limit=${limit}`);
  const products = await response.json();
  return products;
}

export async function getProductsInPromotionCount() {
  const response = await fetch(`${config.API_URL}/products/count?inPromotion=true`);
  const {count} = await response.json();
  return count;
}

export async function getProduct(id) {
  const response = await fetch(`${config.API_URL}/products/${id}`);
  const product = await response.json();
  return product;
}

export async function placeOrder(order){
  const response = await fetch(`${config.API_URL}/orders/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(order),
  });
  if(response.statusCode === 400) throw new Error('Invalid Order');
  const data = await response.json();
  return data;
}

import { createContext, useState, useContext } from "react";
import { useHistory } from "react-router";
import { OrderContext } from "./OrderContext";

export const StageContext = createContext();

const StageProvider = ({ children }) => {
  const history = useHistory();
  const { handleOrderSubmit, isOrderDataValid } = useContext(OrderContext);

  const CART_STAGE = {
    name: "Koszyk",
    order: 1,
    callback: goToCartStage,
    nextStageCallback: goToShipmentStage,
  };

  const SHIPMENT_STAGE = {
    name: "Dostawa i płatność",
    order: 2,
    callback: goToShipmentStage,
    nextStageCallback: goToSummaryStage,
    nextStageText: "Przejdź do podsumowania",
  };

  const SUMMARY_STAGE = {
    name: "Podsumowanie",
    order: 3,
    callback: goToSummaryStage,
    previousStageCallback: goToShipmentStage,
    nextStageCallback: handleOrderSubmit,
    nextStageText: "Kupuję i płacę",
  };

  const READY_STAGE = {
    name: "Gotowe",
    order: 4,
    nextStageText: "Kontynuuj zakupy",
  };

  const [stage, setStage] = useState(SHIPMENT_STAGE);

  function goToShipmentStage() {
    setStage(SHIPMENT_STAGE);
  }

  function goToSummaryStage(recipientErrors, selectedAgreementsErrors) {
    const orderDataValid = isOrderDataValid(
      recipientErrors,
      selectedAgreementsErrors
    );
    if (orderDataValid) setStage(SUMMARY_STAGE);
  }

  function goToCartStage() {
    setStage(CART_STAGE);
    goToCartPage();
  }

  function goToCartPage() {
    history.push("/koszyk");
  }

  return (
    <StageContext.Provider
      value={{
        CART_STAGE,
        SHIPMENT_STAGE,
        SUMMARY_STAGE,
        READY_STAGE,
        stage,
      }}
    >
      {children}
    </StageContext.Provider>
  );
};

export default StageProvider;

import React from "react";

class ErrorBoundary extends React.Component {
  state = { error: false, errorMessage: "" };

  static getDerivedStateFromError(error) {
    return { error: true, errorMessage: error.toString() };
  }

  render() {
    if (this.state.error) {
      return <h1>Coś poszło nie tak.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React, { useState } from 'react';
import SizeInfoButton from "./SizeInfoButton";

import './ChooseVariantPanel.css';
import SizeModal from './SizeModal';

const ChooseVariantPanel = ({availableVariants, variant, setVariant}) => {
  console.log(availableVariants);
  const [showModal, setShowModal] = useState(false);


  const handleShowSizeInfo = () => {
    setShowModal(true);
  }

  const handleCloseSizeInfo = () => {
    setShowModal(false);
  }

  const handleVariantChange = (e) => {
    setVariant(availableVariants.filter(variant => variant.size === e.target.value)[0]);
  }

  return ( 
    <div className="size-panel">
      <select className="size-select" value={variant.size} onChange={handleVariantChange}>
        {availableVariants && availableVariants.map(availableVariant => <option key={availableVariant.size}>{availableVariant.size}</option>)}
      </select>
      <SizeInfoButton onClick={handleShowSizeInfo}/>
      {showModal && <SizeModal onClose={handleCloseSizeInfo}/>}
    </div>
  );
}
 
export default ChooseVariantPanel;
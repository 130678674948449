import { createContext, useEffect, useState, useContext } from "react";
import { getCategories } from "../helpers/Queries";
import { getLSItem, setLSItem, CART_KEY } from "../helpers/LocalStorage";
import { ToastContext } from "./ToastContext";

import {ERROR, SUCCESS} from '../constants/words';

export const ProductContext = createContext();

const Provider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const { showToast } = useContext(ToastContext);

  //Categories
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    async function fetchCategories() {
      try {
        const categories = await getCategories();
        setCategories(categories);
      } catch (err) {}
    }

    await fetchCategories();
  }, []);

  //Cart
  useEffect(() => {
    setCartItems(getLSItem(CART_KEY));
  }, []);

  const addToCart = (product, quantity = 1) => {
    const alreadyInCart = cartItems.findIndex(
      (item) => item.product.id === product.id && item.product.chosenVariant.size === product.chosenVariant.size
    );

    let updatedCartItems;
    if (alreadyInCart > -1) {
      updatedCartItems = [...cartItems];
      updatedCartItems[alreadyInCart].quantity += quantity;
    } else {
      updatedCartItems = [...cartItems, { product, quantity }];
    }

    setCartItems(updatedCartItems);
    setLSItem(CART_KEY, updatedCartItems);
    showToast(SUCCESS, 'Koszyk', 'Dodano produkt do koszyka');
  };

  const removeFromCart = (product) => {
    const updatedCartItems = cartItems.filter((item) => {
      if (
        item.product.id !== product.id || 
        (item.product.id === product.id && item.product.chosenVariant.size !== product.chosenVariant.size)
      ) return true;
      return false;
    });
    setCartItems([...updatedCartItems]);
    setLSItem(CART_KEY, [...updatedCartItems]);
    showToast(ERROR, 'Koszyk', 'Usunięto produkt z koszyka');
  };

  const clearCart = () => {
    setCartItems([]);
    setLSItem(CART_KEY, []);
  };

  const incrementProductQuantity = (product) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.product.id === product.id && item.product.size === product.size && item.quantity < 10) item.quantity += 1;
      return item;
    });
    setCartItems([...updatedCartItems]);
    setLSItem(CART_KEY, [...updatedCartItems]);
  };

  const decrementProductQuantity = (product) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.product.id === product.id && item.product.size === product.size && item.quantity > 1) item.quantity -= 1;
      return item;
    });
    setCartItems([...updatedCartItems]);
    setLSItem(CART_KEY, [...updatedCartItems]);
  };

  return (
    <ProductContext.Provider
      value={{
        categories,
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        incrementProductQuantity,
        decrementProductQuantity,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default Provider;

export function validateRecipient(recipient) {
  const errors = {};

  validateProperty("name", recipient.name, validateName, errors);
  validateProperty("address", recipient.address, validateAddress, errors);
  validateProperty(
    "postalCode",
    recipient.postalCode,
    validatePostalCode,
    errors
  );
  validateProperty("city", recipient.city, validateCity, errors);
  validateProperty("email", recipient.email, validateEmail, errors);
  validateProperty("phone", recipient.phone, validatePhone, errors);

  return errors;
}

export function validateAgreements(agreements) {
  const errors = {};

  validateProperty(
    "acceptTerms",
    agreements.acceptTerms,
    validateAcceptance,
    errors
  );

  return errors;
}

function validateProperty(field, value, callback, errorReference) {
  const error = callback(value);
  if (typeof error === "undefined" || error === "") errorReference[field] = [];
  else errorReference[field] = error;
}

function validateName(name) {
  const maxLength = 50;

  const errorArray = createErrorArray(
    () => isRequiredValid(name),
    () => isMaxLengthValid(name, maxLength),
    () => hasSafeCharacters(name),
    () => hasNumbers(name)
  );

  return errorArray;
}

function validateAddress(address) {
  const maxLength = 50;

  const errorArray = createErrorArray(
    () => isRequiredValid(address),
    () => isMaxLengthValid(address, maxLength),
    () => hasSafeCharacters(address),
    () => hasNoNumbers(address),
  );

  return errorArray;
}

function validatePostalCode(postalCode) {
  const maxLength = 6;

  const errorArray = createErrorArray(
    () => isRequiredValid(postalCode),
    () => isMaxLengthValid(postalCode, maxLength),
    () => hasPostalCodeFormat(postalCode)
  );

  return errorArray;
}

function validateCity(city) {
  const maxLength = 50;

  const errorArray = createErrorArray(
    () => isRequiredValid(city),
    () => isMaxLengthValid(city, maxLength),
    () => hasSafeCharacters(city),
    () => hasNumbers(city)
  );

  return errorArray;
}

function validateEmail(email) {
  const maxLength = 30;

  const errorArray = createErrorArray(
    () => isRequiredValid(email),
    () => isMaxLengthValid(email, maxLength),
    () => hasEmailFormat(email)
  );

  return errorArray;
}

function validatePhone(phone) {
  const errorArray = createErrorArray(
    () => isRequiredValid(phone),
    () => hasPhoneFormat(phone)
  );

  return errorArray;
}

function validateAcceptance(acceptance) {
  const errorArray = createErrorArray(() => isSelected(acceptance));

  return errorArray;
}

function createErrorArray(...callbackArray) {
  const errorArray = [];

  callbackArray.forEach((callback) => errorArray.push(callback()));
  const filteredErrorArray = errorArray.filter((text) => text !== "");

  if (filteredErrorArray.length === 0) return undefined;
  return filteredErrorArray;
}

function isSelected(field) {
  if (field) return "";
  return "Pole musi być zaznaczone";
}

function isMaxLengthValid(text, maxLength) {
  if (text.length > maxLength)
    return `Pole musi zawierać maksymalnie ${maxLength} znaków`;
  return "";
}

function isRequiredValid(text) {
  if (text.length > 0) return "";
  else return "Pole jest wymagane";
}

function hasNumbers(text){
  const format = /[0-9]/;
  const textMatchesCharacter = format.test(text);
  if (textMatchesCharacter) return "Tekst nie może zawierać liczb";
  return "";
}

function hasNoNumbers(text){
  const format = /[0-9]/;
  const textMatchesCharacter = format.test(text);
  if (!textMatchesCharacter) return "Tekst musi zawierać liczbę";
  return "";
}

function hasSafeCharacters(text) {
  const format = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const textMatchesCharacter = format.test(text);
  if (textMatchesCharacter) return "Tekst nie może zawierać znaków specjalnych";
  return "";
}

function hasPostalCodeFormat(text) {
  const postalCodeFormat = /^(\d{2}-\d{3})$/;
  const matchesPostalCodeFormat = postalCodeFormat.test(text);
  if (matchesPostalCodeFormat) return "";
  return "Podany kod pocztowy jest nieprawidłowy. Poprawny format (XX-XXX)";
}

function hasEmailFormat(text) {
  const emailFormat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const matchesEmailFormat = emailFormat.test(text.toLowerCase());
  if (matchesEmailFormat) return "";
  return "Podany adres email jest nieprawidłowy";
}

function hasPhoneFormat(phone) {
  const phoneFormat =
    /^(\+?\d{2}\s?)?(\d{9}$|^\d{3}-\d{3}-\d{3}|\d{3}\s\d{3}\s\d{3})$/;
  const matchesPhoneFormat = phoneFormat.test(phone);
  if (matchesPhoneFormat || phone.length === 0) return "";
  else return "Podany numer jest nieprawidłowy";
}

import React from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Modal = ({ children, title, handleOnClose, handleOnOk, buttonsDisabled = false, showFooter = true }) => {
  return (
    <>
      <div className="modal-gray-background"></div>
      <section className="modal">
        <header>
          <h3>{title}</h3>
          <button disabled={buttonsDisabled} onClick={handleOnClose} className="close">
            <FontAwesomeIcon icon={faTimes} size="1x" />
          </button>
        </header>
        <main>{children}</main>
        {showFooter && <footer>
          <button onClick={handleOnClose} className="close">
            Zamknij
          </button>
          <button disabled={buttonsDisabled} onClick={handleOnOk} className="ok">
            Kontynuuj
          </button>
        </footer>}
      </section>
    </>
  );
};

export default Modal;

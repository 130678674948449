import React, { createContext, useState } from "react";

import checkIcon from '../components/Toast/assets/check.svg';
import errorIcon from '../components/Toast/assets/error.svg';
import infoIcon from '../components/Toast/assets/info.svg';
import warningIcon from '../components/Toast/assets/warning.svg';

import {SUCCESS, ERROR, WARNING, INFO} from '../constants/words';

export const ToastContext = createContext();

const ToastProvider = ({children}) => {
  const [toastList, setToastList] = useState([]);

  const showToast = (type, title, description)  => {
    const id = new Date().getTime().toString();
    let icon;
    switch(type){
      case SUCCESS:
        icon = checkIcon;
        break;
      case ERROR:
        icon = errorIcon;
        break;
      case WARNING:
        icon = warningIcon;
        break;
      case INFO:
      default: 
        icon = infoIcon;
        break;
    }
    const toastProperties = {
      id,
      type,
      title,
      description,
      icon,
    }

    setToastList([...toastList, toastProperties]);
  }

  return (
    <ToastContext.Provider value={{
      toastList,
      setToastList,
      showToast,
    }}>{children}</ToastContext.Provider>
  )
}

export default ToastProvider;
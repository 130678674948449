import React from "react";

import OrderProvider from "../../state/OrderContext";
import LayoutController from "../../features/Order/LayoutController";

import "./OrderPage.css";
import StageProvider from "../../state/StageContext";

const OrderPage = () => {
  return (
    <article className="order">
      <OrderProvider>
        <StageProvider>
          <LayoutController />
        </StageProvider>
      </OrderProvider>
    </article>
  );
};

export default OrderPage;

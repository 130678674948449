import React, { useState, useContext } from "react";

import { ProductContext } from "../../../state/ProductContext";
import Modal from "../../../components/Modal/Modal";
import { OrderContext } from "../../../state/OrderContext";
import Loader from "../../../components/ClipLoader";
import { ToastContext } from "../../../state/ToastContext";
import { ERROR } from "../../../constants/words";

import './P24Modal.css';
import { placeOrder } from "../../../helpers/Queries";

const P24Modal = ({ order }) => {
  const { clearCart } = useContext(ProductContext);
  const {showToast} = useContext(ToastContext);
  const { closePaymentModal, resetSelectedOptions } = useContext(OrderContext);
  const [p24RegulationsAccepted, setP24RegulationsAccepted] = useState(false);
  const [processing, setProcessing] = useState(false);

  //TODO: Dodać żądanie do BE o utworzenie transakcji
  //TODO: Po otrzymaniu transaction ID z BE dodać przekierowanie do P24 z uwzględnieniem callback URL dla sukcesu i niepowodzenia
  const handleP24RegulationsAccepted = () => {
    setP24RegulationsAccepted(prev => !prev);
  }

  const handlePayment = async (event) => {
    event.preventDefault();
    setProcessing(true);

    try {
      const response = await placeOrder(order);
      console.log(response);

      clearCart();
      resetSelectedOptions();
      window.location.replace(response.redirectUrl);
    } catch (error) {
      showToast(ERROR, 'Płatność', 'Wystąpił błąd podczas wykonywania płatności');
    } finally{
      setProcessing(false);
    }
  };

  const handleOnModalClose = () => {
    closePaymentModal();
  };

  return (
    <Modal
      handleOnClose={handleOnModalClose}
      handleOnOk={handlePayment}
      title={"Przelewy24"}
      buttonsDisabled={!p24RegulationsAccepted || processing}
    >
      <section className="p24-payment">
        <label htmlFor="accept-p24">
          <input type="checkbox" name="accept-p24" id="accept-p24" value={p24RegulationsAccepted} onChange={handleP24RegulationsAccepted} />
          <p>„Oświadczam, że zapoznałem się z <a href="https://www.przelewy24.pl/regulamin" target="_blank" rel="noreferrer">regulaminem</a> i <a href="https://www.przelewy24.pl/obowiazek-informacyjny-platnik"  target="_blank" rel="noreferrer">obowiązkiem informacyjnym</a> serwisu Przelewy24”</p>
        </label>
        {processing && <Loader />}
      </section>
    </Modal>
  );
};

export default P24Modal;

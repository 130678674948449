import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { ProductContext } from "../../state/ProductContext";
import Header from "../../layouts/Header/Header";
import Loader from "../../components/PulseLoader";
import ProductList from "../../features/Products/ProductsList";

import { convertToLink } from "../../helpers/RouteMapper";
import { getRecommendedProducts } from "../../helpers/Queries";

import packageImage from "../../images/opakowanie.jpg";
import "./HomePage.css";

const HomePage = () => {
  const { categories } = useContext(ProductContext);

  const categoriesElements = categories.map((category) => {
    if(category.parentId) return null;
    return (
    <Link to={convertToLink(category.name)} key={category.id} className="card">
      <div className="name">{category.name}</div>
      <img
        src={`${category.imageUrl}`}
        alt={category.name}
      />
    </Link>
  )
});

  return (
    <>
      <Header />
      <hr />
      <section className="categories">
        <h2>Produkty dla każdego!</h2>
        {categoriesElements}
        {categories.length ? null : <Loader />}
      </section>
      <hr />
      <ProductList
        name="Polecane produkty"
        productsQuery={getRecommendedProducts}
      />
      <hr />
      <section className="package">
        <h2>Opakowanie</h2>
        <div className="wrapper">
          <img src={packageImage} alt="opakowanie" />
          <p>
            Produkty pakowane są w 100% naturalnie bez użycia plastiku, w
            przyjaznych środowisku tekturowych pudełkach wypełnionych wełną
            drzewną.
          </p>
        </div>
      </section>
    </>
  );
};

export default HomePage;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { ProductContext } from "../../state/ProductContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import { OrderContext } from "../../state/OrderContext";
import PaymentController from "../Payment/PaymentController/PaymentController";
import { StageContext } from "../../state/StageContext";
import { pricify } from "../../helpers/HelperFunctions";

const Bill = () => {
  const { cartItems } = useContext(ProductContext);
  const {
    selectedDelivery,
    selectedOptions,
    amount,
    setAmount,
    recipientErrors,
    selectedAgreementsErrors,
  } = useContext(OrderContext);
  const { stage, SHIPMENT_STAGE } = useContext(StageContext);

  let cartAmount = 0.0;
  let deliveryCost = 0.0;
  let optionsCost = 0.0;

  const calculateCartAmount = () => {
    cartItems.forEach((item) => {
      const price =
        item.product.chosenVariant.promotionPrice > 0
          ? item.product.chosenVariant.promotionPrice
          : item.product.chosenVariant.price;
      const quantity = item.quantity;
      cartAmount += price * quantity;
    });
  };
  const calculateDeliveryCost = () => {
    switch (selectedDelivery.means) {
      case "inpost":
        deliveryCost = 1500;
        break;
      default:
        deliveryCost = 1500;
        break;
    }
  };
  const calculateOptionsCost = () => {
    if (selectedOptions.giftWrapping) optionsCost += 1000;
  };

  calculateCartAmount();
  calculateDeliveryCost();
  calculateOptionsCost();

  useEffect(() => {
    setAmount(cartAmount + deliveryCost + optionsCost);
  }, [amount, optionsCost, deliveryCost, cartAmount]);

  const handleClick = () => {
    if (stage.order === SHIPMENT_STAGE.order) {
      stage.nextStageCallback(recipientErrors, selectedAgreementsErrors);
    } else stage.nextStageCallback();
  };

  return (
    <section className="bill">
      <p>
        Wartość koszyka{" "}
        <span className="cost cart-summed-cost">{pricify(cartAmount)}zł</span>
      </p>
      <p>
        Dostawa{" "}
        <span className="cost delivery-cost">{pricify(deliveryCost)}zł</span>
      </p>
      <p>
        Dodatkowe opcje{" "}
        <span className="cost options-cost">{pricify(optionsCost)}zł</span>
      </p>
      <hr />
      <h3>
        Do zapłaty <span className="total-cost">{pricify(amount)}zł</span>
      </h3>

      <button onClick={handleClick} className="next-stage">
        {stage.nextStageText}
        <FontAwesomeIcon icon={faAngleRight} size="2x" />
      </button>
      <PaymentController />
    </section>
  );
};

export default Bill;

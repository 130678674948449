import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { ProductContext } from "../../state/ProductContext";

import CartPage from "../../pages/CartPage/CartPage";
import CommingSoonPage from "../../pages/CommingSoonPage/CommingSoonPage";
import ContactPage from "../../pages/ContactPage/ContactPage";
import CompanyDetailsPage from "../../pages/CompanyDetailsPage/CompanyDetailsPage";
import HomePage from "../../pages/HomePage/HomePage";
import LatestPage from "../../pages/LatestPage/LatestPage";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
import OrderPage from "../../pages/OrderPage/OrderPage";
import OrderSuccededPage from "../../pages/OrderSuccededPage/OrderSuccededPage";
import PackageOptionsPage from "../../pages/PackageOptionsPage/PackageOptionsPage";
import PaymentMethodsPage from "../../pages/PaymentMethodsPage/PaymentMethodsPage";
import PrivacyPolicyPage from "../../pages/PrivacyPolicyPage/PrivacyPolicyPage";
import ProcessingTimePage from "../../pages/ProcessingTimePage/ProcessingTimePage";
import PromotionsPage from "../../pages/PromotionsPage/PromotionsPage";
import RegulationsPage from "../../pages/RegulationsPage/RegulationsPage";
import ReturnsPage from "../../pages/ReturnsPage/ReturnsPage";

import ScrollToTop from "../../components/ScrollToTop";

import {
  convertToLink,
  createProductDetailsRoute,
  createCategoryRoute,
} from "../../helpers/RouteMapper";
import {
  getCategoriesProducts,
  getCategoriesProductsCount,
} from "../../helpers/Queries";
import ErrorBoundary from "../../components/ErrorBoundary";

const Page = () => {
  const { categories } = useContext(ProductContext);
  const categoriesProductsRoutes = [],
    subcategoriesProductsRoutes = [],
    productDetailsRoutes = [];

  //TODO: zupełnie zmienić routing po stronie klienta, wykorzystać parametry routingu w linkach (np. produkty/:id)
  categories.forEach((category) => {
    const categoryLinkedName = convertToLink(category.name);
    const isTopLevelCategory = true;
    categoriesProductsRoutes.push(
      createCategoryRoute(
        categoryLinkedName,
        isTopLevelCategory,
        category,
        (page, limit) => getCategoriesProducts(category.id, page, limit),
        () => getCategoriesProductsCount(category.id)
      )
    );

    category.subcategories.forEach((subcategory) => {
      const subcategoryLinkedName = convertToLink(subcategory.name);
      subcategoriesProductsRoutes.push(
        createCategoryRoute(
          `${categoryLinkedName}${subcategoryLinkedName}`,
          false,
          subcategory,
          (page, limit) =>
            getCategoriesProducts(subcategory.id, page, limit),
          () => getCategoriesProductsCount(subcategory.id)
        )
      );
      if(categoryLinkedName){
        productDetailsRoutes.push(
          createProductDetailsRoute(
            subcategory.id.concat("-id"),
            `${categoryLinkedName}${subcategoryLinkedName}/:id`
          )
        );
      } else {
        productDetailsRoutes.push(createProductDetailsRoute(subcategory.id.concat("-id"), `${subcategoryLinkedName}/:id`))
      }
      
    });
  });

  return (
    <main>
      <ErrorBoundary>
        <ScrollToTop />
        <Switch>
          <Route component={HomePage} exact path="/" />
          <Route component={CartPage} path="/koszyk" />
          <Route component={CompanyDetailsPage} path='/dane-firmy'/>
          <Route component={ContactPage} path='/kontakt'/>
          <Route component={LatestPage} path="/nowosci" />
          <Route component={OrderSuccededPage} path="/zamowienie/gotowe" />
          <Route component={OrderPage} path="/zamowienie" />
          <Route component={PackageOptionsPage} path="/opcja-pakowania" />
          <Route component={PaymentMethodsPage} path='/formy-platnosci'/>
          <Route component={PrivacyPolicyPage} path='/polityka-prywatnosci'/>
          <Route component={ProcessingTimePage} path='/czas-realizacji'/>
          <Route component={PromotionsPage} path="/promocje" />
          {categoriesProductsRoutes}
          {subcategoriesProductsRoutes}
          {productDetailsRoutes}
          <Route component={RegulationsPage} path='/regulamin' />
          <Route component={ReturnsPage} path='/zwroty' />
          <Route component={CommingSoonPage} path='/ustawienia-konta' />
          <Route component={CommingSoonPage} path='/przechowalnia' />
          <Route component={CommingSoonPage} path='/moje-zamowienia' />
          <Route component={NotFoundPage} />
        </Switch>
      </ErrorBoundary>
    </main>
  );
};

export default Page;

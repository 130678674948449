import React, { useEffect, useState } from "react";

import { config } from "../../config/config";
import Pager from "./Pager";
import ProductCard from "./ProductCard";
import Loader from "../../components/PulseLoader";

import {PRODUCTS_LIMIT, FIRST_PAGE} from '../../constants/numbers';

const ProductsList = ({ name, productsQuery, countQuery, categoryImage, hidePager }) => {
  const [products, setProducts] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [page, setPage] = useState(FIRST_PAGE);
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(PRODUCTS_LIMIT);
  const [totalCount, setTotalCount] = useState(0);
  const [isPagerShown, setIsPagerShown] = useState(true);

  useEffect(() => {
    async function fetchProductsCount() {
      try {
        const count = await countQuery();
        setTotalCount(count);
      } catch (err) {}
    }

    fetchProductsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchProducts() {
      try {
        const products = await productsQuery(page, limit);
        setProducts(
          products.map((product) => (
            <ProductCard product={product} key={product.id} />
          ))
        );
        setIsFetching(false);
      } catch (err) {
        setIsFetching(false);
        setProducts((prev) =>
          prev.push(
            <h3>Wystąpił błąd podczas wczytywania produktów, przepraszamy.</h3>
          )
        );
      }
    }

    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setIsPagerShown(!(hidePager || totalCount <= limit));
  }, [hidePager, totalCount, limit]);

  return (
    <section className="products">
      {categoryImage ? <img className="category-image"
          src={`${config.API_URL}${categoryImage.url}`}
          alt={categoryImage.name}
        /> : <h2>{name}</h2>}
      {products}
      {isPagerShown && (
        <Pager
          page={page}
          setPage={setPage}
          limit={limit}
          totalCount={totalCount}
        />
      )}
      {isFetching ? <Loader /> : null}
    </section>
  );
};

export default ProductsList;

import { BrowserRouter as Router } from "react-router-dom";
import ProductsProvider from "../../state/ProductContext";
import ToastProvider from "../../state/ToastContext";

import Navbar from "../Navigation/Navbar";
import Page from "../Page/Page";
import Footer from "../Footer/Footer";
import Toast from "../../components/Toast/Toast";

function App() {
  return (
    <div className="app">
      <Router>
        <ToastProvider>
          <ProductsProvider>
            <Navbar />
            <Page />
            <Footer />
            <Toast />
          </ProductsProvider>
        </ToastProvider>
      </Router>
    </div>
  );
}

export default App;

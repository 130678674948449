import React, { useContext } from "react";
import CartItemsList from "../../features/Cart/CartItemsList/CartItemsList";
import CartSummary from "../../features/Cart/CartSummary/CartSummary";
import { ProductContext } from "../../state/ProductContext";

import "./CartPage.css";

const CartPage = () => {
  const { cartItems } = useContext(ProductContext);

  return (
    <article className="cart">
      <h1>Koszyk</h1>
      {cartItems.length ? (
        <>
          <CartItemsList />
          <CartSummary />
        </>
      ) : (
        <h4>Twój koszyk jest pusty</h4>
      )}
    </article>
  );
};

export default CartPage;

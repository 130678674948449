import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";

import "./ProductDetails.css";

import { config } from "../../../config/config";
import CountInput from "../CountInput";
// import RatePanel from "./RatePanel";
import AddToCartButton from "../AddToCartButton";
import CopyLinkButton from "../CopyLinkButton";
import ChooseVariantPanel from "./ChooseVariantPanel";

import { getProduct } from "../../../helpers/Queries";
import { pricify } from "../../../helpers/HelperFunctions";

const ProductsDetails = ({ id }) => {
  const [product, setProduct] = useState({
    variants: [],
  });
  const [chosenVariant, setChosenVariant] = useState({});
  const [availableVariants, setAvailableVariants] = useState([]);
  const [images, setImages] = useState([]);
  const [count, setCount] = useState(1);

  useEffect(() => {
    async function fetchProduct() {
      try {
        const product = await getProduct(id);
        setProduct(product);
        setImages([
          ...product.images.map((image) => {
            return {
              original: `${config.API_URL}${image.url}`,
              thumbnail: `${config.API_URL}${image.url}`,
            };
          }),
        ]);
        const defaultVariant = {
          size: 'M',
          price: product.price,
          promotionPrice: product.promotionPrice,
        }
        const variants = [defaultVariant, ...product.variants];
        setChosenVariant(defaultVariant);
        setAvailableVariants(product.variants ? variants : []);
      } catch (err) {
        console.log(err);
      }
    }
    fetchProduct();
  }, [id]);

  useEffect(() => {
    setProduct((prev) => {
      return {
        ...prev,
        chosenVariant,
      }
    })
  }, [chosenVariant]);

  const isInPromotion = product.promotionPrice > 0;
  const priceElement = (
    <p className={isInPromotion ? "price in-promotion" : "price"}>
      Cena:{" "}
      {isInPromotion ? (
        <>
          <strong className="in-promotion">{pricify(chosenVariant.promotionPrice)}zł</strong>
          <small>{pricify(chosenVariant.price)}zł</small>
        </>
      ) : (
        <strong>{pricify(chosenVariant.price)}zł</strong>
      )}
    </p>
  );

  const handleDecrementCount = (e) => {
    e.preventDefault();
    setCount((prev) => {
      if (prev === 1) return 1;
      else return prev - 1;
    });
  };

  const handleIncrementCount = (e) => {
    e.preventDefault();
    setCount((prev) => {
      if (prev === 10) return 10;
      else return prev + 1;
    });
  };

  const productComponent = (
    <article className="product-details">
      <section className="main-info">
        <ImageGallery items={images} />
        <div className="details">
          <h1>{product.name}</h1>
          {priceElement}
          <CountInput
            decrement={handleDecrementCount}
            increment={handleIncrementCount}
            count={count}
          />
          {product.isSizeRequired ? <ChooseVariantPanel availableVariants={availableVariants} variant={chosenVariant} setVariant={setChosenVariant}/> : null}
          <AddToCartButton product={product} quantity={count} />
          <p className="availability">
            <span>Dostępność: </span>
            {product.available ? (
              <span>Dostępny</span>
            ) : (
              <span className="error">Niedostępny</span>
            )}
          </p>
          <p className="sku">
            <span>Kod produktu: </span>
            <span>{product.sku}</span>
          </p>
          <p className="ask-for-product">
            <a href="/kontakt">Zapytaj o produkt</a>
            <CopyLinkButton />
          </p>
          {/* <RatePanel /> */}
        </div>
      </section>
      <hr />
      <section className="description">
        <h3>Opis</h3>
        <pre>{product.description}</pre>
      </section>
      {/* <hr />
      <section className="similar-products">
        <h3>Podobne produkty</h3>
      </section>
      <hr />
      <section className="opinions">
        <h3>Opinie</h3>
      </section> */}
    </article>
  );

  return (
    <article className="product-details">
      {product ? productComponent : <h3>Nie odnaleziono produktu</h3>}
    </article>
  );
};

export default ProductsDetails;

import React, { useContext } from "react";

import { StageContext } from "../../../state/StageContext";

import "./StagesBar.css";

const StagesBar = () => {
  const { stage, CART_STAGE, SHIPMENT_STAGE, SUMMARY_STAGE, READY_STAGE } =
    useContext(StageContext);

  return (
    <section className="stages-container">
      <div className="stage stage-completed" onClick={CART_STAGE.callback}>
        <span>{CART_STAGE.order}</span>
        <p>{CART_STAGE.name}</p>
      </div>
      <hr />
      <div
        className={`stage ${
          stage.order > SHIPMENT_STAGE.order
            ? "stage-completed"
            : "stage-active"
        }`}
        onClick={SHIPMENT_STAGE.callback}
      >
        <span>{SHIPMENT_STAGE.order}</span>
        <p>{SHIPMENT_STAGE.name}</p>
      </div>
      <hr />
      <div
        className={`stage ${
          stage.order === SUMMARY_STAGE.order
            ? "stage-active"
            : stage.order > SUMMARY_STAGE.order
            ? "stage-completed"
            : ""
        }`}
      >
        <span>{SUMMARY_STAGE.order}</span>
        <p>{SUMMARY_STAGE.name}</p>
      </div>
      <hr />
      <div
        className={`stage ${
          stage.order === READY_STAGE.order
            ? "stage-active"
            : stage.order > READY_STAGE.order
            ? "stage-completed"
            : ""
        }`}
      >
        <span>{READY_STAGE.order}</span>
        <p>{READY_STAGE.name}</p>
      </div>
    </section>
  );
};

export default StagesBar;

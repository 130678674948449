import React, { useState, useEffect, useContext } from 'react';
import {ToastContext} from '../../state/ToastContext';

import {TOAST_DURATION} from '../../constants/numbers';

import './Toast.css';

const Toast = () => {
    const { toastList, setToastList } = useContext(ToastContext);
    const [list, setList] = useState(toastList);

    useEffect(() => {
        setList([...toastList]);
    }, [toastList]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (toastList.length && list.length) {
                deleteToast(toastList[0].id);
            }
        }, TOAST_DURATION);
        
        return () => {
            clearInterval(interval);
        }

        // eslint-disable-next-line
    }, [toastList, list]);

    const deleteToast = id => {
        const listItemIndex = list.findIndex(e => e.id === id);
        const toastListItem = toastList.findIndex(e => e.id === id);

        list.splice(listItemIndex, 1);
        toastList.splice(toastListItem, 1);

        setList([...list]);
        setToastList([...toastList]);
    }

    return (
            <div className={`notification-container bottom-right`}>
                {
                    list.map((toast, i) =>     
                        <div 
                            key={i}
                            className={`notification toast bottom-right ${toast.type}`}
                        >
                            <button onClick={() => deleteToast(toast.id)}>
                                X
                            </button>
                            <div className="notification-image">
                                <img src={toast.icon} alt="" />
                            </div>
                            <div>
                                <p className="notification-title">{toast.title}</p>
                                <p className="notification-message">
                                    {toast.description}
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
    );
}

export default Toast;
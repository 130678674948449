import React, { useContext } from "react";
import "./CartButton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { ProductContext } from "../../../state/ProductContext";
import { useHistory } from "react-router";

const CartButton = () => {
  const { cartItems } = useContext(ProductContext);
  const history = useHistory();

  const cartCount = cartItems.reduce((total, current) => {
    return (total += current.quantity);
  }, 0);

  const handleOnClick = () => {
    history.push("/koszyk");
  };

  return (
    <button className="cart-button" onClick={handleOnClick}>
      <FontAwesomeIcon icon={faShoppingBag} size="4x" />
      {cartItems.length ? <div className="cart-count">{cartCount}</div> : null}
    </button>
  );
};

export default CartButton;

import { Route, NavLink } from "react-router-dom";
import ProductsPage from "../pages/ProductsPage/ProductsPage";
import ExpandMenuButton from "../layouts/Navigation/ExpandMenuButton";

export function convertToLink(phrase) {
  return (
    "/" +
    phrase
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase()
  );
}

//TODO: zupełnie zmienić routing po stronie klienta, wykorzystać parametry routingu w linkach (np. produkty/:id)
export function mapCategoriesToLinks(
  categories,
  categoriesCallback,
  subcategoriesCallback
) {
  return categories.map((category) => {
    if(category.parentId !== null) return null;
    const categoryLink = convertToLink(category.name);
    return (
      <li className="category" key={category.id}>
        <NavLink to={categoryLink} onClick={categoriesCallback}>
          {category.name}
        </NavLink>
        <ul>
          {category.subcategories.map((subcategory) => {
            const subcategoryLink = convertToLink(subcategory.name);
            return (
              <li className="sublink" key={category.id + "-" + subcategory.id}>
                <NavLink
                  to={`${categoryLink}${subcategoryLink}`}
                  onClick={subcategoriesCallback}
                >
                  {subcategory.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <ExpandMenuButton />
      </li>
    );
  });
}

export function createCategoryRoute(path, isTopLevelCategory, category, productsQuery, countQuery) {
  const image = category.image !== undefined ? category.image[0] : null;
  return (
    <Route
      key={category.id}
      render={() => (
        <ProductsPage
          name={category.name}
          isTopLevelCategory={isTopLevelCategory} 
          categoryImage={image}
          productsQuery={productsQuery}
          countQuery={countQuery}
        />
      )}
      exact
      path={path}
    />
  );
}

export function createProductDetailsRoute(key, path) {
  return <Route key={key} component={ProductsPage} path={path} />;
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const OpenMenuButton = ({ action }) => {
  return (
    <button onClick={action} className="open-menu">
      <FontAwesomeIcon icon={faBars} size="3x" />
    </button>
  );
};

export default OpenMenuButton;

import { useEffect } from "react";
import { useParams } from "react-router";

import SideMenu from "../../layouts/SideMenu/SideMenu";
import ProductsList from "../../features/Products/ProductsList";
import ProductDetails from "../../features/Products/ProductDetails/ProductDetails";
import "./ProductsPage.css";

const ProductsPage = ({ name, isTopLevelCategory, categoryImage, productsQuery, countQuery }) => {
  const params = useParams();

  useEffect(() => {
    const main = document.querySelector("main");
    main.classList.add("products");
    return () => {
      main.classList.remove("products");
    };
  }, []);

  return (
    <>
      <SideMenu />
      {params.id ? (
        <ProductDetails id={params.id} />
      ) : (
        <ProductsList
          name={name}
          productsQuery={productsQuery}
          countQuery={countQuery}
          categoryImage={categoryImage}
        />
      )}
    </>
  );
};

export default ProductsPage;

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { config } from "../../../config/config";
import { ProductContext } from "../../../state/ProductContext";
import { convertToLink } from "../../../helpers/RouteMapper";

import "./CartItemsList.css";
import CountInput from "../../Products/CountInput";
import { pricify } from "../../../helpers/HelperFunctions";

const CartItemsList = () => {
  const {
    cartItems,
    removeFromCart,
    incrementProductQuantity,
    decrementProductQuantity,
  } = useContext(ProductContext);

  const handleOnClick = (product) => removeFromCart(product);

  const handleIncrementQuantity = (product) => {
    incrementProductQuantity(product);
  };
  const handleDecrementQuantity = (product) => {
    decrementProductQuantity(product);
  };

  const cartItemsToDisplay = cartItems.map((item) => {
    const { product, quantity } = item;
    const categoryLink = convertToLink(product.category.parentCategory.name);
    const subcategoryLink = convertToLink(product.category.name);

    const isInPromotion = product.chosenVariant.promotionPrice > 0;
    const priceElement = (
      <p className={isInPromotion ? "total in-promotion" : "total"}>
        {isInPromotion ? (
          <>
            <strong>
              {pricify(product.chosenVariant.promotionPrice * quantity) + "zł"}
            </strong>
            <small>{pricify(product.chosenVariant.price * quantity) + "zł"}</small>
          </>
        ) : (
          pricify(product.chosenVariant.price * quantity) + "zł"
        )}
      </p>
    );
    const sizeElement = item.product.isSizeRequired ? `(${item.product.chosenVariant.size})` : '';

    return (
      <li key={`${product.id}${product.size}`}>
        <img
          src={`${config.API_URL}${product.images[0]}`}
          alt={product.name}
        />
        <div className="product-details">
          <NavLink
            to={`${categoryLink}${subcategoryLink}/${product.id}`}
            className="product-name"
          >
            {`${item.product.name}${sizeElement}`}
          </NavLink>
          <p className="price">
            {isInPromotion
              ? pricify(product.chosenVariant.promotionPrice)
              : pricify(product.chosenVariant.price)}
            zł
          </p>
        </div>
        <div className="order-details">
          <CountInput
            count={quantity}
            increment={() => handleIncrementQuantity(product)}
            decrement={() => handleDecrementQuantity(product)}
          />
          {priceElement}
          <button
            className="remove-item-button"
            onClick={() => handleOnClick(product)}
          >
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </button>
        </div>
      </li>
    );
  });

  return (
    <section className="cart-content">
      <ul className="cart-list">{cartItemsToDisplay}</ul>
    </section>
  );
};

export default CartItemsList;

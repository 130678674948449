import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SizeInfoButton = ({onClick}) => {
  return ( 
      <span className="size-info" onClick={onClick}>
        <FontAwesomeIcon icon={faInfoCircle} size="2x" />
      </span> 
      );
}
 
export default SizeInfoButton;
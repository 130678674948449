import Modal from '../../../components/Modal/Modal';

import './SizeModal.css';

const SizeModal = ({onClose}) => {
  return ( 
  <Modal
    title="Tabela rozmiarów"
    showFooter={false}
    handleOnClose={onClose}
    >
      <table className='size-table'>
        <thead>
          <tr>
            <th>Rozmiar</th>
            <th>Wymiary</th>
            <th>Wiek</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>S</td>
            <td>4,5 x 8 cm</td>
            <td>0 - 2 lata</td>
          </tr>
          <tr>
            <td>M</td>
            <td>5 x 9 cm</td>
            <td>3 - 7 lat</td>
          </tr>
          <tr>
            <td>L</td>
            <td>5,5 x 10,5 cm</td>
            <td>8 - 15 lat</td>
          </tr>
        </tbody>
      </table>
  </Modal> );
}
 
export default SizeModal;
import React, { useContext } from "react";
import { ProductContext } from "../../state/ProductContext";

const AddToCartButton = ({ product, quantity }) => {
  const { addToCart } = useContext(ProductContext);

  const handleButtonClick = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    addToCart(product, quantity);
  };

  return (
    <button className="add-to-checkout" onClick={handleButtonClick}>
      Dodaj do koszyka
    </button>
  );
};

export default AddToCartButton;

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const CountInput = ({ decrement, increment, count }) => {
  return (
    <label htmlFor="count" className="count-input">
      <button className="decrement-count" onClick={decrement}>
        <FontAwesomeIcon icon={faMinus} size="2x" />
      </button>
      <input type="number" id="count" min="1" max="10" value={count} readOnly />
      <button className="increment-count" onClick={increment}>
        <FontAwesomeIcon icon={faPlus} size="2x" />
      </button>
    </label>
  );
};

export default CountInput;

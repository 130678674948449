import React, { useContext } from "react";
import { OrderContext } from "../../state/OrderContext";
import {pricify} from '../../helpers/HelperFunctions';

const Shipment = () => {
  const {
    recipient,
    recipientErrors,
    handleRecipientChange,
    deliveries,
    selectedDelivery,
    handleSelectedDeliveryChange,
    payments,
    selectedPayment,
    handleSelectedPaymentChange,
    options,
    selectedOptions,
    handleSelectedOptionsChange,
    selectedAgreements,
    selectedAgreementsErrors,
    handleSelectedAgreementsChange,
    showErrors,
  } = useContext(OrderContext);

  const recipientForm = createRecipientForm();
  const deliveryForm = createDeliveryForm();
  const paymentForm = createPaymentForm();
  const optionsForm = createOptionsForm();
  const agreementsForm = createAgreementsForm();

  function createRecipientForm() {
    const nameErrors = recipientErrors.name.map((error, id) => (
      <p className="error" key={id}>
        {error}
      </p>
    ));
    const addressErrors = recipientErrors.address.map((error, id) => (
      <p className="error" key={id}>
        {error}
      </p>
    ));
    const cityErrors = recipientErrors.city.map((error, id) => (
      <p className="error" key={id}>
        {error}
      </p>
    ));
    const postalCodeErrors = recipientErrors.postalCode.map((error, id) => (
      <p className="error" key={id}>
        {error}
      </p>
    ));
    const emailErrors = recipientErrors.email.map((error, id) => (
      <p className="error" key={id}>
        {error}
      </p>
    ));
    const phoneErrors = recipientErrors.phone.map((error, id) => (
      <p className="error" key={id}>
        {error}
      </p>
    ));
    return (
      <form noValidate>
        <label htmlFor="name">
          <input
            type="text"
            id="name"
            name="name"
            value={recipient.name}
            onChange={handleRecipientChange}
          />
          <span>Imię i nazwisko *</span>
        </label>
        {showErrors && nameErrors}
        <label htmlFor="address">
          <input
            type="text"
            id="address"
            name="address"
            value={recipient.address}
            onChange={handleRecipientChange}
          />
          <span>Ulica i numer *</span>
        </label>
        {showErrors && addressErrors}
        <label htmlFor="postalCode">
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={recipient.postalCode}
            onChange={handleRecipientChange}
          />
          <span>Kod pocztowy *</span>
        </label>
        {showErrors && postalCodeErrors}
        <label htmlFor="city">
          <input
            type="text"
            id="city"
            name="city"
            value={recipient.city}
            onChange={handleRecipientChange}
          />
          <span>Miejscowość *</span>
        </label>
        {showErrors && cityErrors}
        <label htmlFor="email">
          <input
            type="email"
            id="email"
            name="email"
            value={recipient.email}
            onChange={handleRecipientChange}
          />
          <span>E-mail *</span>
        </label>
        {showErrors && emailErrors}
        <label htmlFor="phone">
          <input
            type="text"
            id="phone"
            name="phone"
            value={recipient.phone}
            onChange={handleRecipientChange}
          />
          <span>Numer telefonu</span>
        </label>
        {showErrors && phoneErrors}
      </form>
    );
  }
  function createDeliveryForm() {
    const labels = deliveries.map((delivery) => (
      <label key={delivery.means} htmlFor={delivery.means}>
        <input
          type="radio"
          name={delivery.means}
          id="delivery"
          value={delivery.means}
          checked={selectedDelivery.means === delivery.means}
          onChange={handleSelectedDeliveryChange}
        />
        <span>
          {delivery.fullName}{" "}
          <span className="shipment-price">
            ({pricify(delivery.price)}zł)
          </span>
        </span>
        <img src={delivery.image} alt={delivery.fullName} />
      </label>
    ));
    return <form>{labels}</form>;
  }
  function createPaymentForm() {
    const labels = payments.map((payment) => (
      <label key={payment.method} htmlFor={payment.method}>
        <input
          type="radio"
          name="payment-radio"
          id={payment.method}
          value={payment.method}
          checked={selectedPayment.method === payment.method}
          onChange={handleSelectedPaymentChange}
        />
        <span>{payment.fullName}</span>
        <img src={payment.image} alt={payment.fullName} />
      </label>
    ));
    return <form>{labels}</form>;
  }
  function createOptionsForm() {
    const labels = options.map((option) => (
      <label key={option.abbreviation} htmlFor={option.abbreviation}>
        <input
          type="checkbox"
          id={option.abbreviation}
          checked={selectedOptions[option.abbreviation]}
          onChange={handleSelectedOptionsChange}
        />
        <span>
          {option.fullName} <span>({pricify(option.price)}zł)</span>
        </span>
      </label>
    ));

    return <form>{labels}</form>;
  }
  function createAgreementsForm() {
    const acceptTermsError = selectedAgreementsErrors.acceptTerms.map(
      (error, id) => (
        <p className="error" key={id}>
          {error}
        </p>
      )
    );
    return (
      <form>
        <label htmlFor="accept-terms">
          <input
            type="checkbox"
            id="accept-terms"
            checked={selectedAgreements.acceptTerms}
            onChange={handleSelectedAgreementsChange}
          />
          <span>Akceptuję regulamin *</span>
        </label>
        {showErrors && acceptTermsError}
        <strong>* Pola wymagane</strong>
      </form>
    );
  }

  return (
    <section className="shipment">
      <h1>Dostawa i płatność</h1>
      <section className="recipient">
        <h2>1. Dane odbiorcy</h2>
        {recipientForm}
      </section>
      <section className="delivery">
        <h2>2. Sposób dostawy</h2>
        {deliveryForm}
      </section>
      <section className="payment">
        <h2>3. Metoda płatności</h2>
        {paymentForm}
      </section>
      <section className="options">
        <h2>4. Dodatkowe opcje</h2>
        {optionsForm}
      </section>
      <section className="agreements">
        <h2>Zgody formalne</h2>
        {agreementsForm}
      </section>
    </section>
  );
};

export default Shipment;

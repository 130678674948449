import React from "react";
import { NavLink } from "react-router-dom";
import "./OrderSuccededPage.css";

const OrderSuccededPage = () => {
  return (
    <section className="finish-order">
      <h1>Rozpoczęliśmy realizację zamówienia</h1>
      <p>Informacje o stanie zamówienia będą wysyłane na Twój adres email.</p>
      <NavLink to="/">Kontynuuj zakupy</NavLink>
    </section>
  );
};

export default OrderSuccededPage;
